import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation } from 'react-router-dom';
import Alianza from './inicio/alianza'//?
import Noticias from './inicio/noticias'
import Afiliados from './inicio/afiliados'
import Nosotros from './inicio/nosotros'
import Contacto from './inicio/contacto'
import Registro from './inicio/registro'
import FormApoyo from './inicio/registro/forms/apoyo'
import FormAliado from './inicio/registro/forms/aliado'
import FormBrigada from './inicio/registro/forms/brigada'
import Privacidad from './inicio/privacidad'
import NavAnps from './navs/NavAnps'//?
import NavUnidos from './navs/NavUnidos'
import MainHeader from './navs/MainHeader'
import UnidosvsDiabetes from './unidosvsdiabetes'
import RudosvsTecnicos from './rudosvstecnicos'
import Header from './Header'
import About from './About'
import Noticias2 from './Noticias'
import Blog from './Blog'
import ImagesCarousel from './ImagesCarousel'
import Contacto2 from './Contacto'
import NuevosAliados from './NuevosAliados'
import Noticia from './Noticias/noticia'
import Campaña from './Blog/campana'

const Dashboard = () => {

	let location = useLocation();

	return (
		<Router>
		{location.pathname==='/unidosvsdiabetes'?<NavUnidos />:<MainHeader />
		}
			<Switch>
				<Route path='/' exact>
					<Header title = 'ANPS' subtitle='-Alianza Nacional de Prevención y Salud' text='"La felicidad del cuerpo se funda en la salud, la del entendimiento, en el saber."' textcolor='#ffffff' />
					<About />
					<Noticias2 />
					<NuevosAliados />
					<Blog />
					<ImagesCarousel />
				</Route>
				<Route path='/registrate' exact><Registro /></Route>
				<Route path='/registrate/apoyo' exact><FormApoyo /></Route>
				<Route path='/registrate/aliado' exact><FormAliado /></Route>
				<Route path='/registrate/brigada' exact><FormBrigada /></Route>
				<Route path='/privacidad' exact><Privacidad /></Route>
				<Route path='/unidosvsdiabetes' exact><UnidosvsDiabetes /></Route>
				<Route path='/rudosvstecnicos' exact><RudosvsTecnicos /></Route>
				<Route path='/noticia/:noticia_id' exact><Noticia /></Route>
				<Route path='/campaña/:campana_link' exact><Campaña /></Route>
			</Switch>
			<Contacto2 dir='Dirección: Calz. Lázaro Cárdenas #3609, primer piso, Col. Jardín de San Ignacio, C.P. 45040, Zapopan, Jal.' phone='Tel. 33 2312 4052' email='contacto@anpsmexico.org'   />
		</Router>
	)
}

export default Dashboard
