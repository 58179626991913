import React, {useEffect,useState} from 'react';																							       
import Menu from './Menu';
import Search from './Search';

const MainHeader = () => {

	let bgHeader ='#24415B';   
	const [navbarcolor, setNavbar] = useState(false)

	useEffect(() => {
		changeBackground()
		window.addEventListener('scroll', changeBackground)
	})

	const changeBackground = () => { 
		if (window.scrollY >= 100)setNavbar(true)  
		else setNavbar(false)
	}

	return (
		<header className='container-fluid m-0 p-0 cont-menu' style={{zIndex:4,background: navbarcolor ? bgHeader : 'linear-gradient(0deg, rgba(255, 255, 255, 0)0%, rgba(136, 135, 135, 0.3) 35%, rgba(0, 0, 0, .6)100%)' }}>
			<div className='rowi m-0 pl-3 pr-3'>
				<div className='col-md-10i m-0 p-0'>
					<Menu textoMenu = '#ffffff' />
				</div>
				{/*<div className='col-md-2 m-0 p-2'>
					<Search lineColor = '#ffffff' textoColor ='#ffffff' iconColor = '#ffffff' />
				</div>*/}
			</div>
		</header>
	);
};
 
export default MainHeader;
