import React from 'react';
import TituloSection from '../TituloSection';
import Btncustom from '../Btncustom';

const About = () => {

	return (
		<div id='nosotros' className='container-fluid my-4'>
			<div className='row p-4'>
				<div className='col-12 col-md-5 my-3 pr-4'>
					<div className=''>
						<TituloSection titulo='¿QUIENES SOMOS?' tituloColor='#06294A' colorLine='#24B9B6' />
					</div>
					<div style={{fontSize:'15px'}}>
						<p>Somos una organización altruista que promueve la salud.</p>
						<p>Nuestras acciones tienen lugar en todo México y esperamos abrir camino para un alcance global, juntos podemos hacer la diferencia y mejorar la vida de miles de personas.</p>
						<p>Ante la necesidad y el gran reto de salvaguardar la salud, creamos lazos de cooperación entre empresas, personas y organizaciones con el objetivo de contribuir, impulsar, fortalecer, cuidar y fomentar el derecho fundamental a la salud. Buscamos responder al llamado universal de los	<strong>Objetivos de Desarrollo Sostenible</strong> encausados firmemente en el objetivo de <strong>La Salud y el Bienestar.</strong></p>
					</div>
				</div>
				<div className='col-12 col-md-7 my-3 text-center'>
					<div style={{height:'350px'}}>
						<img alt='quienes somos' src='/resources/sunset.jpg' className='img-fluid rounded-lg' alt='...' style={{maxHeight:'350px'}} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
