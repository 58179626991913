import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import Nav from './nav'
import Contacto from './menu/contacto'
import Aliados from './menu/aliados'
import Apoyo from './menu/apoyo'

const Dashboard = ({user}) => {

	let match = useRouteMatch();

	return (
		<>
			<Nav />
			<Switch>
				<Route path={`${match.path}`} exact>
					<div style={{height:'100vh',display:'flex', alignItems:'center', justifyContent:'center'}}>Bienvenido {user.name}</div>
				</Route>
				<Route path={`${match.path}/contactos`}><Contacto /></Route>
				<Route path={`${match.path}/aliados`}><Aliados /></Route>
				<Route path={`${match.path}/apoyo`}><Apoyo /></Route>
			</Switch>
		</>
	)
}

export default Dashboard
