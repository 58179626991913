const noticias = [
	{
		id:1,
		titulo:'AVANCES CAMPAÑA "UNIDOS VS DIABETES"',
		descripcion:'Evento que se llevo a cabo por parte de la ANPS donde asistieron diferentes medios de comunicación donde el Presidente de la Asociación Juan Álvaro Jiménez Romero presento los avances de la campaña Unidosvsdiabetes , así como también el Vampiro Canadiense nos dio unas motivadoras palabras de lo que es la lucha más grande de su vida y lo que se viene para esta proximamente.',
		descripcionBreve:'Evento que se llevo a cabo por parte de la ANPS donde asistieron diferentes medios de comunicación donde el Presidente…',
		portada:{titulo:'#unidosvsdiabetes',url:'/resources/preview/noticias/noticia1-1.jpg'},
		link:'',
		imagenes:[{titulo:'#unidosvsdiabetes',url:'/resources/noticias/noticia1-1.jpg',link:''},{titulo:'#unidosvsdiabetes',url:'/resources/noticias/noticia1-2.jpg',link:''}],
		videos:[]
	},
	{
		id:2,
		titulo:'SIGUEN LAS DONACIONES DE LA CAMPAÑA “PROTEGE AQUIEN TE CUIDA”',
		descripcion:'En busca de proteger y salvaguardar la salud de nuestros héroes realizamos donaciones de insumos médicos a distintos hospitales con el fin de que todo el cuerpo médico pueda seguir con sus actividades de una manera segura.',
		descripcionBreve:'En busca de proteger y salvaguardar la salud de nuestros héroes realizamos donaciones de insumos médicos a distintos hospitales…',
		portada:{titulo:'Hospital Universitario',url:'/resources/preview/noticias/noticia2-1.jpg'},
		link:'',
		imagenes:[{titulo:'Hospital Universitario',url:'/resources/noticias/noticia2-1.jpg',link:''},{titulo:'Hospital Civil',url:'/resources/noticias/noticia2-2.jpg',link:''},{titulo:'Hospital General Regional',url:'/resources/noticias/noticia2-3.jpg',link:''},{titulo:'Hospital Para el Niño IMIEM',url:'/resources/noticias/noticia2-4.jpg',link:''},{titulo:'Centro Médico (Estado de México)',url:'/resources/noticias/noticia2-5.jpg',link:''},{titulo:'Hospital General (Dr. Nicolas San Juan)',url:'/resources/noticias/noticia2-6.jpg',link:''},{titulo:'Hospital Materno Perinatal (Mónica Pretelini Saenz).',url:'/resources/noticias/noticia2-7.jpg',link:''},{titulo:'Hospital Regional De Nezahualcóyotl',url:'/resources/noticias/noticia2-8.jpg',link:''},{titulo:'Centro de salud Tejalpa',url:'/resources/noticias/noticia2-9.jpg',link:''}],
		videos:[]
	},
	{
		id:3,
		titulo:'Hogares de Ancianos A.C.',
		descripcion:'Entregamos 60 Suplementos VITA DEYUN en la casa de retiro Hogares de Ancianos A.C. para ayudar en el cuidado de la salud de sus residentes.',
		descripcionBreve:'Entregamos 60 Suplementos VITA DEYUN en la casa de retiro…',
		portada:{titulo:'ANPS',url:'/resources/preview/noticias/noticia3-1.jpg'},
		link:'',
		imagenes:[{titulo:'ANPS', url:'/resources/noticias/noticia3-1.jpg',link:''}],
		videos:[]
	},
	{
		id:4,
		titulo:'El Vampiro Canadiense y la lucha mas grande de su vida',
		descripcion:'Se lanza la campaña Rudosvstecnicos en conjunto de la ANPS con uno de sus aliados más fuertes, Aquadeyun, donde las personas con Diabetes participaran para ser uno de los 5 ganadores donde los visitara el Vampiro Canadiense en cualquier parte de la Republica Mexicana para ayudarlos a tener una mejor calidad de vida.',
		descripcionBreve:'Se lanza la campaña Rudosvstecnicos en conjunto de la ANPS con uno de sus aliados más fuertes…',
		portada:{titulo:'Vampiro',url:'/resources/preview/noticias/noticia4-1.jpg'},
		link:'',
		imagenes:[{titulo:'Vampiro', url:'/resources/noticias/vampiro.jpg',link:''}],
		videos:[]
	},
	{
		id:5,
		titulo:'Capsulas informativas',
		descripcion:'Para brindarte la información de mayor valor y calidad, trabajamos en tener un canal informativo en YouTube con los mejores especialistas de la salud, enfocando su expertis en temas relacionados con la Diabetes.',
		descripcionBreve:'Para brindarte la información de mayor valor y calidad, trabajamos en tener un canal informativo en…',
		portada:{titulo:'Capsulas',url:'/resources/preview/noticias/noticia5-1.jpg'},
		link:'',
		imagenes:[{titulo:'capsulas', url:'/resources/noticias/capsulas.jpg',link:'https://youtube.com/anpsmexico'}],
		videos:[]
	},
	{
		id:6,
		titulo:'Convenio de Cooperación con la AMMEF',
		descripcion:'Convenio de cooperación en la asamblea nacional de la asociación mexicana de médicos en formación, donde se firmó en vivo un convenio de colaboración donde la ANPS va estar donando y aportando en diferentes áreas que se establecieron en el convenio para los estudiantes.',
		descripcionBreve:'Convenio de cooperación en la asamblea nacional de la asociación mexicana de médicos en formación, donde se firmó en vivo un convenio de …',
		portada:{titulo:'Convenio',url:'/resources/preview/noticias/noticia6-1.jpg'},
		link:'',
		imagenes:[{titulo:'Convenio', url:'/resources/noticias/noticia6-1.jpg',link:''}],
		videos:[]
	}
]

export default noticias
