import React from 'react'
import { Link } from 'react-router-dom'
import cuadro1 from '../../assets/unidosvsdiabetes/Fondos/que-es.jpg'
import fondoAlianzas from '../../assets/unidosvsdiabetes/Fondos/nuestras-alianzas.jpg'
import fondoBeneficios from '../../assets/unidosvsdiabetes/Fondos/beneficios.jpg'
import fondoRegistrate from '../../assets/unidosvsdiabetes/Fondos/registrate.jpg'
import a1 from '../../assets/unidosvsdiabetes/img/Logos/01.jpg'
import a2 from '../../assets/unidosvsdiabetes/img/Logos/02.jpg'
import a3 from '../../assets/unidosvsdiabetes/img/Logos/03.jpg'
import a4 from '../../assets/unidosvsdiabetes/img/Logos/04.jpg'
import a5 from '../../assets/unidosvsdiabetes/img/Logos/05.jpg'
import a6 from '../../assets/unidosvsdiabetes/img/Logos/06.jpg'
import a7 from '../../assets/unidosvsdiabetes/img/Logos/07.jpg'
import a8 from '../../assets/unidosvsdiabetes/img/Logos/08.jpg'
import a9 from '../../assets/unidosvsdiabetes/img/Logos/09.jpg'
import a10 from '../../assets/unidosvsdiabetes/img/Logos/10.jpg'
import a11 from '../../assets/unidosvsdiabetes/img/Logos/11.jpg'
import r1 from '../../assets/unidosvsdiabetes/img/registrate-1.jpg'
import r2 from '../../assets/unidosvsdiabetes/img/registrate-2.jpg'

const UnidosvsDiabetes = () => {
	return(
		<div id='unidosvsdiabetes'>
		<div id='section-1' className='section1' style={{backgroundImage:'url('+cuadro1+')'}}>
			<div style={{maxWidth:500}}>
				<div style={{fontSize:28}}>¿Qué es</div>
				<div style={{fontSize:35, fontWeight:'bold'}}>Unidos vs Diabetes?</div>
			</div>
			<div style={{fontSize:20, maxWidth:800}}>
				<p>Esta campaña ayuda a mejorar la calidad de vida de las personas que padecen diabetes y a reducir el impacto que tiene en México esta condición.</p>
				<p>Nuestras acciones incluyen cápsulas educativas a través de médicos especialistas, capacitaciones y actualizaciones con la ayuda de los colegios y asociaciones que forman parte de esta alianza, y lo más importante, creamos beneficios gratuitos así como descuentos en productos y servicios de todas las empresas que se unen a esta causa.</p>
			</div>
		</div>

		<div id='section-2' className='section2' style={{backgroundImage:'url('+fondoAlianzas+')'}}>
			<center>
				<h4 style={{fontSize:40, color:'grey'}}>Nuestras Alianzas</h4>
					<div style={{padding:30,maxWidth:900, background:'white', borderRadius:30, boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}>
						<div className='m-row' style={{color:'grey', fontSize:15, display:'flex', flexWrap: 'wrap',justifyContent:'center'  }}>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Colegio Médico de México A.C.' src={a1} />
								<div style={{width:120}}>Colegio Médico de México A.C.</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Federación Mexicana de Diabetes A.C.' src={a2}/>
								<div style={{width:150}}>Federación Mexicana de Diabetes A.C.</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Diabetes Vive Feliz' src={a3}/>
								<div>Diabetes Vive Feliz</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Asociación Mexicana de Médicos en Formación A.C.' src={a4}/>
								<div style={{width:190}}>Asociación Mexicana de Médicos en Formación A.C.</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Asociación Mexicana de Estudiantes de Enfermería' src={a5}/>
								<div style={{width:190}}>Asociación Mexicana de Estudiantes de Enfermería</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Colegio de Médicos Especialistas en Nutrición del Estado de Jalisco' src={a6}/>
								<div style={{width:230}}>Colegio de Médicos Especialistas en Nutrición del Estado de Jalisco</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Laboratorios Chopo' src={a7}/>
								<div style={{width:120}}>Laboratorios Chopo</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Nosotrxs' src={a8}/>
								<div style={{width:120}}>Nosotrxs</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Aqua Deyun' src={a9}/>
								<div style={{width:200}}>Aqua Deyun</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='Verde Miel' src={a10}/>
								<div style={{width:120}}>Verde Miel</div>
							</div>
							<div className='col' style={{marginBottom:10}}>
								<img alt='D’almendra' src={a11}/>
								<div style={{width:120}}>D’almendra</div>
						</div>
					</div>
				</div>
			</center>
		</div>
		<div id='section-3' className='section3' style={{backgroundImage:'url('+fondoBeneficios+')'}}>
			<h4 style={{fontSize:50, color:'white'}}>Beneficios</h4>
			<center style={{marginTop:180}}>
				<img alt='laboratirios chopo' src={a7} width='340'/>
			</center>
			<div className='m-row'>
				<div className='col m9'>
					<div style={{borderBottom:'3px solid #1C57A3', maxWidth:250}}>
						<h4 style={{marginBottom:5}}>DESCUENTOS ESPECIALES</h4>
					</div>
					<div className='m-row' style={{marginTop:40}}>
						<div className='col m6'>
							<div style={{height:80}}>
								<div style={{fontSize:18, fontWeight:'bold'}}>Amilasa en suero $145.00</div>
								<div>(Precio normal $355.00)</div>									
							</div>
						</div>
						<div className='col m6'>
							<div style={{height:80}}>
								<div style={{fontSize:18, fontWeight:'bold'}}>Lipasa en suero $141.00</div>
								<div>(Precio normal $381.50)</div>									
							</div>
						</div>
						<div className='col m6'>
							<div style={{height:80}}>
								<div style={{fontSize:18, fontWeight:'bold'}}>Química sanguínea de 5 elementos $217.00</div>
								<div>(Precio normal $301.50)</div>									
							</div>
						</div>
						<div className='col m6'>
							<div style={{height:80}}>
								<div style={{fontSize:18, fontWeight:'bold'}}>Hemoglobina glicosilada A1C $214.00</div>
								<div>(Precio normal $363.30)</div>									
							</div>
						</div>
						<div className='col m6'>
							<div style={{height:80}}>
								<div style={{fontSize:18, fontWeight:'bold'}}>Curva en tolerancia a la glucosa en 2 horas $255.00</div>
								<div>(Precio normal $486.50)</div>									
							</div>
						</div>
						<div className='col m6'>
							<div style={{height:80}}>
								<div style={{fontSize:18, fontWeight:'bold'}}>Examen General de Orina $104.00</div>
								<div>(Precio normal $144.00)</div>									
							</div>
						</div>
					</div>
				</div>
				<div className='col m3'>
					<div style={{borderBottom:'3px solid #1C57A3', maxWidth:150}}>
						<h4 style={{marginBottom:5}}>ADICIONAL</h4>
					</div>
					<div style={{borderLeft:'3px solid grey', paddingLeft:20, minHeight:300}}>
						<h4 style={{fontSize:28}}>20% EN ESTUDIOS LABORATORIALES</h4>
						<h4 style={{fontSize:28}}>15% EN ESTUDIOS DE GABINETE</h4>
						<h4 style={{fontSize:28}}>5% EN ESTUDIOS ESPECIALES</h4>
					</div>
				</div>
			</div>
			<div className='m-row'>
				<div style={{borderTop:'3px solid grey', marginTop:100, paddingTop:50}}>
					<center>
						<img alt='aqua deyun' src={a9}/>
						<div style={{color:'grey', fontSize:18}}>Aqua Deyun</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Con el 15% de descuento en este producto,<br />el cual sirve para disminuir los altos niveles de azúcar en la sangre,<br />baja el porcentaje de Hemoglobina glicosilada y es 100% natural.</p>
					</center>
				</div>
				<div style={{borderTop:'3px solid grey', marginTop:100, paddingTop:50}}>
					<center>
						<img alt='verdemiel' src={a10}/>
						<div style={{color:'grey', fontSize:18}}>Verdemiel</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Restaurante healthy.<br /> 10% de descuento en productos elaborados por la casa.</p>
					</center>
				</div>
				<div style={{borderTop:'3px solid grey', marginTop:100, paddingTop:50}}>
					<center>
						<img alt='D’almendra Keto Bakery' src={a11}/>
						<div style={{color:'grey', fontSize:18}}>D’almendra Keto Bakery</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Repostería libre de azúcar y gluten.<br />20% de descuento en productos elaborados por la casa</p>
					</center>
				</div>
			</div>
		</div>
		<div id='section-4' className='section4' style={{backgroundImage:'url('+fondoRegistrate+')'}}>
			<center>
				<h4 style={{fontSize:40, color:'grey', fontWeight:'bold'}}>Regístrate</h4>
				<Link to='/registrate/apoyo' style={{display:'inline-block', verticalAlign:'middle'}}>
					<div className='card2' style={{color:'#2B3663',borderRadius:10,padding:20,maxWidth:270}}>
						<img alt='Regístrate para obtener los beneficios y nuestros programas de apoyo.' src={r1} alt='img' width='200'/>
						<p style={{textAlign:'justify'}}>Regístrate para obtener los beneficios y nuestros programas de apoyo.</p>
					</div>
				</Link>
				<Link to='/registrate/aliado' style={{display:'inline-block', verticalAlign:'middle'}}>
					<div className='card2' style={{color:'#2B3663',borderRadius:10,padding:20,maxWidth:270}}>
						<img alt='Regístrate como aliado y ayúdanos a promover la salud, todos los esfuerzos suman.' src={r2} alt='img' width='200'/>
						<p style={{textAlign:'justify'}}>Regístrate como aliado y ayúdanos a promover la salud, todos los esfuerzos suman.</p>
					</div>
				</Link>
			</center>
		</div>
		</div>
	)
	
}

export default UnidosvsDiabetes
