import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import cuadro1 from '../../../../assets/cuadro1.png'
import { estados } from '../../../../geo/estados'
import { ciudades } from '../../../../geo/ciudades'
import axios from '../../../../axios'
import delgado from './delgado.png'
import normal from './normal.png'
import robusto from './robusto.png'
import salud_muymala from './salud_muymala.png'
import salud_mala from './salud_mala.png'
import salud_regular from './salud_regular.png'
import salud_buena from './salud_buena.png'
import salud_muybuena from './salud_muybuena.png'

const Brigada = () => {

	const padrescon={diabetes:false,colesterol:false,hipertension:false,cancer:false,asma:false,depresion:false,ninguna:false,otra:'',otraB:false}
	const [loader, setLoader] = useState(false)
	const [data, setData] = useState({nombre:'',apellido_paterno:'',apellido_materno:'', correo:'', telefono:'', domicilio:'',cp:'', estado:'', ciudad:'', fechaNacimiento:{dia:'',mes:'',anio:''}, genero:'', cuerpo:'',
			tengo:{diabetes:false,colesterol:false,hipertension:false,cancer:false,asma:false,depresion:false,otra:'',otraB:false},
			tuvecovid:'', padrescon, megustariamejorar:'', estado_civil:'', ocupacion:'', hijos:'', cant_hijos:0, estatura:'', facil_enojar:'', facil_reir:'', facil_entristecer:'', salud_actual:'',
			seguro_medico:'', checkeo_general:'', checkeo_especificado:'', fumo_bebo:'', frecuencia_fumar_beber:'', medico_de_confianza:'', megustaria_ser_donador:'', daño_en_cuerpo:'', daño_en_cuerpo_descripcion:'',
			alergia:'', alergia_descripcion:'', tuvecovid_durante:'', megustaria_economizar_medicamento:'', inversion_para_mi_salud:0, })
	
	const dias = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
	const mes = ['01','02','03','04','05','06','07','08','09','10','11','12']

	const [brigadaInputs, setBrigadaInputs] = useState({peso:'',medida_cintura:'', presion_arterial:'', saturacion_sangre:'', glucosa_sanguinea:'',
		masa_corporal:'', frecuencia_cardiaca:'', temperatura:'', estatus_pulmones:'', inspeccion_fisica:'', adicciones:'', depresion:'', siento_cansancio_todo_el_dia:'',
		tolero_mal_los_cambios_de_temperatura:'', estoy_engordando:'', perdida_de_cabello:'', periodos_menstruales_abundantes:'', heces_duras:'', antes_menstruar_retengo_liquidos:'',
		semana_anterior_la_paso_mal:'', periodos_dolorosos_abundantes:'', senos_sensibles:'', ciclos_irregulares:'', estoy_nerviosa_lloro:'', ardor_estomago:'', contracturas_calambres_espasmos:'',
		inflamacion_artrosis_artritis_tendinitis_dolores:'', lengua_pastosa_mal_aliento:'', sensible_olores:'', cuesta_digerir_pesado:'', somnolencia_tras_comer:'', dolor_cabeza_frecuente:'',
		exposicion_quimicos:'', hinchado_lleno_de_gases:'', dolores_intestinales_colicos_colitis:'', diarrea_estreñimiento:''})
	
	useEffect(()=>{
		//window.scrollTo(0, 0);
	},[])

	const anios = () =>{
		let anios=[]
		for (let i=1936;i<=new Date().getFullYear();i++) anios.push(String(i))
		return anios.reverse()
	}

	const _push = (e) => {
		e.preventDefault()
		if(!/^[0-9]{10}$/.test(data.telefono)){
			alert('ingrese un teléfono válido')
				return 0
		}
		if(data.cuerpo===''){
			alert('Elija su tipo de cuerpo')
			return 0
		}
		
		setLoader(true)
		axios.post('/programabrigada',{...data, ...brigadaInputs, fechaNacimiento:data.fechaNacimiento.anio+'/'+data.fechaNacimiento.mes+'/'+data.fechaNacimiento.dia})
		.then(r=>{
			console.log(r.data)
			setLoader(false)
			setData({nombre:'',apellido_paterno:'',apellido_materno:'', correo:'', telefono:'',domicilio:'',cp:'', estado:'', ciudad:'', fechaNacimiento:{dia:'',mes:'',anio:''}, genero:'', cuerpo:'',
				tengo:{diabetes:false,colesterol:false,hipertension:false,cancer:false,asma:false,depresion:false,otra:''},
				tuvecovid:'',
				padrescon,
				megustariamejorar:''
			})
			setData({nombre:'',apellido_paterno:'',apellido_materno:'', correo:'', telefono:'',domicilio:'',cp:'', estado:'', ciudad:'', fechaNacimiento:{dia:'',mes:'',anio:''}, genero:'', cuerpo:'',
				tengo:{diabetes:false,colesterol:false,hipertension:false,cancer:false,asma:false,depresion:false,otra:'',otraB:false},
				tuvecovid:'', padrescon, megustariamejorar:'', estado_civil:'', ocupacion:'', hijos:'', cant_hijos:0, estatura:'', facil_enojar:'', facil_reir:'', facil_entristecer:'', salud_actual:'',
				seguro_medico:'', checkeo_general:'', checkeo_especificado:'', fumo_bebo:'', frecuencia_fumar_beber:'', medico_de_confianza:'', megustaria_ser_donador:'', daño_en_cuerpo:'', daño_en_cuerpo_descripcion:'',
				alergia:'', alergia_descripcion:'', tuvecovid_durante:'', megustaria_economizar_medicamento:'', inversion_para_mi_salud:0, })
			setBrigadaInputs({peso:'',medida_cintura:'', presion_arterial:'', saturacion_sangre:'', glucosa_sanguinea:'',
				masa_corporal:'', frecuencia_cardiaca:'', temperatura:'', estatus_pulmones:'', inspeccion_fisica:'', adicciones:'', depresion:'', siento_cansancio_todo_el_dia:'',
				tolero_mal_los_cambios_de_temperatura:'', estoy_engordando:'', perdida_de_cabello:'', periodos_menstruales_abundantes:'', heces_duras:'', antes_menstruar_retengo_liquidos:'',
				semana_anterior_la_paso_mal:'', periodos_dolorosos_abundantes:'', senos_sensibles:'', ciclos_irregulares:'', estoy_nerviosa_lloro:'', ardor_estomago:'', contracturas_calambres_espasmos:'',
				inflamacion_artrosis_artritis_tendinitis_dolores:'', lengua_pastosa_mal_aliento:'', sensible_olores:'', cuesta_digerir_pesado:'', somnolencia_tras_comer:'', dolor_cabeza_frecuente:'',
				exposicion_quimicos:'', hinchado_lleno_de_gases:'', dolores_intestinales_colicos_colitis:'', diarrea_estreñimiento:''})
			alert('Gracias por registrarte con nosotros!')
		})
		.catch(r=>{
			if(r.response.status===400){
				alert(r.response.data.message)
				setLoader(false)
			}
			else alert(r)
		})
	}

	return(
		<div id='registrate' style={{backgroundImage:'url(https://www.bibliotecabrincar.org.ar/wp-content/uploads/2021/03/group-friends-gathering-together-scaled.jpg)',backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundAttachment:'fixed',paddingTop:200,paddingBottom:100, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.05)'}}>
			<center>
				<div style={{display:'block',paddingBottom:100, paddingTop:0}}>
					<form onSubmit={_push}>
						<div style={{margin:0,color:'white', fontSize:30, fontWeight:900}}>
							<div style={{position:'relative',display:'inline-block', padding:20}}>
								<div style={{display:'inline', borderBottom:'4px solid #25969F'}}>Regí</div>strate
							</div>
						</div>
						<div className='card2'>
							<div style={{fontSize:16, fontWeight:'bold', textTransform:'uppercase'}}>Regístrate en nuestros programa Brigada</div>
								<p style={{textAlign:'justify'}}>Queremos promover la salud, regístrate como miembro para recibir los beneficios que la ANPS tiene para ti. ¡Por un México más Saludable!</p>
								<div style={{textAlign:'left'}}>
									<div>
										<label className="bold" htmlFor='nombres'>Nombre(s):</label>
										<input value={data.nombre} onChange={(e)=>setData({...data,nombre:e.target.value})} id='nombres' type='text' required/>
									</div>
									<div>
										<label className="bold" htmlFor='apellido_paterno'>Apellido Paterno:</label>
										<input value={data.apellido_paterno} onChange={(e)=>setData({...data,apellido_paterno:e.target.value})} id='apellido_paterno' type='text' required/>
									</div>
									<div>
										<label className="bold" htmlFor='apellido_materno'>Apellido Materno:</label>
										<input value={data.apellido_materno} onChange={(e)=>setData({...data,apellido_materno:e.target.value})} id='apellido_materno' type='text' required/>
									</div>

									<div style={{marginTop:20,marginBottom:20}}>
										<h5>Fecha de nacimiento:</h5>
										<div style={{display:'flex',flexWrap: 'wrap'}}>
											<div style={{marginRight:5}}>
												<label style={{display:'block'}}>Día:</label>
												<select style={{width:100,height:44, fontSize:20}} value={data.fechaNacimiento.dia} onChange={(e)=>setData({...data, fechaNacimiento:{...data.fechaNacimiento,dia:e.target.value}})} required>
													<option value=''>día</option>
													{dias.map((el, i) =>
													<option key={i} value={el}>{el}</option>
													)}
												</select>
											</div>
											<div style={{marginRight:5}}>
												<label style={{display:'block'}}>Mes:</label>
												<select style={{width:100,height:44, fontSize:20}} value={data.fechaNacimiento.mes} onChange={(e)=>setData({...data, fechaNacimiento:{...data.fechaNacimiento,mes:e.target.value}})} required>
													<option value=''>Mes</option>
													{mes.map((el, i) =>
													<option key={i} value={el}>{el}</option>
													)}
												</select>
											</div>
										<div>
										<label htmlFor='' style={{display:'block'}}>Año:</label>
										<select style={{width:200,height:44, fontSize:20}} value={data.fechaNacimiento.anio} onChange={(e)=>setData({...data, fechaNacimiento:{...data.fechaNacimiento,anio:e.target.value}})} required>
											<option value=''>año</option>
											{anios().map((el, i) =>
											<option key={i} value={el}>{el}</option>
											)}
										</select>
									</div>
								</div>
							</div>

							<div>
								<label className="bold" htmlFor='correo'>Correo electrónico:</label>
								<input value={data.correo} onChange={(e)=>setData({...data,correo:e.target.value})} id='correo' type='email' required/>
							</div>
							<div>
								<label className="bold" htmlFor='telefono'>Telefono:</label>
								<input value={data.telefono} onChange={(e)=>setData({...data,telefono:e.target.value})} id='telefono' type='text' required/>
								<span style={{color:/^[0-9]{10}$/.test(data.telefono)?'green':'red'}}>{/^[0-9]{10}$/.test(data.telefono)?'teléfono válido':'teléfono inválido'}</span>
							</div>
							<div>
								<h5 style={{marginTop:20, textAlign:'center'}}>Domicilio</h5>
								<label className="bold" htmlFor='direccion'>Dirección:</label>
								<input value={data.direccion} onChange={(e)=>setData({...data,direccion:e.target.value})} id='direccion' type='text' required/>
								<label className="bold" htmlFor='cp'>CP:</label>
								<input value={data.cp} onChange={(e)=>setData({...data,cp:e.target.value})} id='cp' type='text' required/>
							</div>
	
							<div style={{marginRight:5, width:'100%'}}>
								<label className="bold" htmlFor='' style={{display:'block'}}>Estado:</label>
								<select style={{width:'100%',height:44, fontSize:20}} value={data.estado} onChange={(e)=>setData({...data, estado:e.target.value})}  id='estado' required>
									<option value=''>Seleccione un opción</option>
									{estados.map(e=><option key={e.key} value={e.name}>{e.name}</option>)}
								</select>
							</div>
							<div style={{width:'100%'}}>
								<label className="bold" htmlFor='' style={{display:'block'}}>Ciudad:</label>
								<select style={{width:'100%',height:44, fontSize:20}} value={data.ciudad} onChange={(e)=>setData({...data, ciudad:e.target.value})} required>
									<option value=''>Seleccionar Ciudad</option>
										{
										data.estado&&
										ciudades.filter(c=>c.state_id===estados.find(e=>e.name===data.estado).id).map(c=><option key={c.id} value={c.name}>{c.name}</option>)
										}
								</select>
							</div>

							<div style={{marginRight:5, width:'100%'}}>
								<label className="bold" htmlFor='' style={{display:'block'}}>Estado Civil:</label>
								<select style={{width:'100%',height:44, fontSize:20}} value={data.estado_civil} onChange={(e)=>setData({...data, estado_civil:e.target.value})} id='estado_civil' required>
									<option value=''>Seleccione un opción</option>
									<option value='Casado'>Casado</option>
									<option value='Soltero'>Soltero</option>
									<option value='Viudo'>Viudo</option>
									<option value='Unión libre'>Unión libre</option>
								</select>
							</div>
							<div>
								<label className="bold" htmlFor='ocupacion'>Ocupación:</label>
								<input value={data.ocupacion} onChange={(e)=>setData({...data,ocupacion:e.target.value})} id='ocupacion' type='text' required/>
							</div>

							<div style={{marginTop:20}}>
								<h5>¿Tienes hijos?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:50}} htmlFor='hijos_si'>Sí</label>
										<input style={{width:100}} type='radio' id='hijos_si' name='hijos' checked={data.hijos===1} onChange={(e)=>setData({...data, hijos:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:50}} htmlFor='hijos_no'>No</label>
										<input style={{width:100}} type='radio' id='hijos_no' name='hijos' checked={data.hijos===0} onChange={(e)=>setData({...data, hijos:0})} required/>
									</div>
								</div>
								{data.hijos===1&&
								<div style={{marginRight:5,marginTop:10, width:'100%'}}>
									<label className="bold" htmlFor='' style={{display:'block'}}>¿Cuantos?</label>
									<select style={{width:'100%',height:44, fontSize:20}} value={data.cant_hijos} onChange={(e)=>setData({...data, cant_hijos:e.target.value})} required>
										<option value=''>Seleccione un opción</option>
										{[...Array(9)].map((x, i) =>
										<option value={i+1}>{i+1}</option>
										)}
									</select>
								</div>
								}
							</div>
				
							<div style={{marginTop:50,marginBottom:20}}>
								<h5>Género</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:50}} htmlFor='hombre'>Hombre</label>
										<input style={{width:100}} type='radio' id='hombre' name='genero' checked={data.genero=='Hombre'} onChange={(e)=>setData({...data, genero:'Hombre'})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:50}} htmlFor='mujer'>Mujer</label>
										<input style={{width:100}} type='radio' id='mujer' name='genero' checked={data.genero=='Mujer'} onChange={(e)=>setData({...data, genero:'Mujer'})} required/>
									</div>
								</div>
							</div>

							<div>
								<label className="bold" htmlFor='estatura'>Estatura:</label>
								<select style={{width:'100%',height:44, fontSize:20}} value={data.estatura} onChange={(e)=>setData({...data, estatura:e.target.value})} required>
									<option value=''>Seleccione un opción</option>
									{[...Array(190)].map((x, i) =>{
									if(i>50)
										return <option value={(i+1)/100}>{(i+1)/100} m</option>
									}
									)}
								</select>
							</div>

							<div style={{marginTop:50}}>
								<h5>Tipo de complexión:</h5>
								<div style={{display:'flex',flexWrap: 'wrap', alignItems:'center', justifyContent:'center'}}>
									<div style={{width:150, textAlign:'center'}}>
										<img alt='delgado' src={delgado} style={data.cuerpo==='Delgado'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, cuerpo:'Delgado'})}/>
										<label style={{width:50}} htmlFor='delgado'>Delgado</label>
									</div>
									<div style={{width:150, textAlign:'center'}}>
										<img alt='normal' src={normal} style={data.cuerpo==='Normal'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, cuerpo:'Normal'})}/>
										<label style={{width:50}} htmlFor='normal' onClick={()=>setData({...data, cuerpo:'Normal'})}>Normal</label>
									</div>
									<div style={{width:150, textAlign:'center'}}>
										<img alt='robusto' src={robusto} style={data.cuerpo==='Robusto'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, cuerpo:'Robusto'})}/>
										<label style={{width:50}} htmlFor='robusto' onClick={()=>setData({...data, cuerpo:'Robusto'})}>Robusto</label>
									</div>
								</div>
							</div>

							<div style={{marginTop:50}}>
								<h5>Consideras que es fácil hacerte...</h5>
								<div style={{display:'flex',flexWrap: 'wrap', alignItems:'center'}}>
									<div style={{width:100}}>Enojar:</div>
									<div style={{display:'flex', alignItems:'center', marginLeft:30}}>
										<label style={{width:50, margin:0}} htmlFor='si_enojo'>Sí</label>
										<input style={{width:30}} type='radio' id='si_enojo' name='enojo' checked={data.facil_enojar===1} onChange={(e)=>setData({...data, facil_enojar:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center', marginLeft:30}}>
										<label style={{width:50, margin:0}} htmlFor='no_enojo'>No</label>
										<input style={{width:30}} type='radio' id='no_enojo' name='enojo' checked={data.facil_enojar===0} onChange={(e)=>setData({...data, facil_enojar:0})} required/>
									</div>
								</div>

								<div style={{display:'flex',flexWrap: 'wrap', alignItems:'center'}}>
									<div style={{width:100}}>Reír:</div>
									<div style={{display:'flex', alignItems:'center', marginLeft:30}}>
										<label style={{width:50, margin:0}} htmlFor='si_reir'>Sí</label>
										<input style={{width:30}} type='radio' id='si_reir' name='reir' checked={data.facil_reir===1} onChange={(e)=>setData({...data, facil_reir:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center', marginLeft:30}}>
										<label style={{width:50, margin:0}} htmlFor='no_reir'>No</label>
										<input style={{width:30}} type='radio' id='no_reir' name='reir' checked={data.facil_reir===0} onChange={(e)=>setData({...data, facil_reir:0})} required/>
									</div>
								</div>

								<div style={{display:'flex',flexWrap: 'wrap', alignItems:'center'}}>
									<div style={{width:100}}>Entristecer:</div>
									<div style={{display:'flex', alignItems:'center', marginLeft:30}}>
										<label style={{width:50, margin:0}} htmlFor='si_entristecer'>Sí</label>
										<input style={{width:30}} type='radio' id='si_entristecer' name='entristecer' checked={data.facil_entristecer===1} onChange={(e)=>setData({...data, facil_entristecer:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center', marginLeft:30}}>
										<label style={{width:50, margin:0}} htmlFor='no_entristecer'>No</label>
										<input style={{width:30}} type='radio' id='no_entristecer' name='entristecer' checked={data.facil_entristecer===0} onChange={(e)=>setData({...data, facil_entristecer:0})} required/>
									</div>
								</div>
							</div>

							<div style={{marginTop:50}}>
								<h5>¿Como calificarías tu salud actual?</h5>
								<div style={{display:'flex',flexWrap: 'wrap', alignItems:'center', justifyContent:'center'}}>
									<div style={{width:100, textAlign:'center'}}>
										<img alt='salud muy mala' width='100' src={salud_muymala} style={data.salud_actual==='Muy Mala'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, salud_actual:'Muy Mala'})}/>
										<label>Muy Mala</label>
									</div>
									<div style={{width:100, textAlign:'center'}}>
										<img alt='salud mala' width='100' src={salud_mala} style={data.salud_actual==='Mala'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, salud_actual:'Mala'})}/>
										<label>Mala</label>
									</div>
									<div style={{width:100, textAlign:'center'}}>
										<img alt='salud regular' width='100' src={salud_regular} style={data.salud_actual==='Regular'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, salud_actual:'Regular'})}/>
										<label>Regular</label>
									</div>
									<div style={{width:100, textAlign:'center'}}>
										<img alt='salud buena' width='100' src={salud_buena} style={data.salud_actual==='Buena'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, salud_actual:'Buena'})}/>
										<label>Buena</label>
									</div>
									<div style={{width:100, textAlign:'center'}}>
										<img alt='salud muy buena' width='100' src={salud_muybuena} style={data.salud_actual==='Muy Buena'?{border: 'solid #2B3663',cursor:'pointer',boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}:{cursor:'pointer'}} onClick={()=>setData({...data, salud_actual:'Muy Buena'})}/>
										<label>Muy Buena</label>
									</div>
								</div>
							</div>

							<div style={{marginTop:50}}>
								<h5>¿Tienes Seguro Médico?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='seguro_privado'>Privado</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='seguro_privado' name='seguro' checked={data.seguro_medico=='Privado'} onChange={(e)=>setData({...data, seguro_medico:'Privado'})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='seguro_publico'>Publico</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='seguro_publico' name='seguro' checked={data.seguro_medico=='Publico'} onChange={(e)=>setData({...data, seguro_medico:'Publico'})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='seguro_notengo'>No Tengo</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='seguro_notengo' name='seguro' checked={data.seguro_medico=='No Tengo'} onChange={(e)=>setData({...data, seguro_medico:'No Tengo'})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>¿Cada cuánto te haces un chequeo general de salud?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='checkeo_6meses'>6 Meses</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='checkeo_6meses' name='checkeo_general' checked={data.checkeo_general==='6 Meses'} onChange={(e)=>setData({...data, checkeo_general:'6 Meses'})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='checkeo_1año'>1 Año</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='checkeo_1año' name='checkeo_general' checked={data.checkeo_general==='1 Año'} onChange={(e)=>setData({...data, checkeo_general:'1 Año'})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='checkeo_otro'>Otro</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='checkeo_1año' name='checkeo_general' checked={data.checkeo_general==='Otro'} onChange={(e)=>setData({...data, checkeo_general:'Otro'})} required/>
									</div>
								</div>
								{data.checkeo_general==='Otro'&&
								<div style={{display:'block'}}>
									<label htmlFor='nombre'>Especifique:</label>
									<input value={data.checkeo_especificado} type='text' onChange={(e)=>setData({...data, checkeo_especificado:e.target.value})} required/>
								</div>
								}
							</div>

							<div style={{marginTop:50}}>
								<h5>¿Fumas o Bebes Alcohol?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='sifumo'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='sifumo' name='fumo' checked={data.fumo_bebo===1} onChange={(e)=>setData({...data, fumo_bebo:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='nofumo'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='nofumo' name='fumo' checked={data.fumo_bebo===0} onChange={(e)=>setData({...data, fumo_bebo:0})} required/>
									</div>
								</div>
								{data.fumo_bebo===1&&
								<div style={{display:'block'}}>
									<div style={{marginRight:5, width:'100%'}}>
										<label htmlFor='' style={{display:'block'}}>Seleccione una opción:</label>
										<select style={{width:'100%',height:44, fontSize:20}} value={data.frecuencia_fumar_beber} onChange={(e)=>setData({...data, frecuencia_fumar_beber:e.target.value})} required>
											<option value=''>Seleccione un opción</option>
											<option value='1 a 2 días por semana'>1 a 2 días por semana</option>
											<option value='3 a 5 días por semana'>3 a 5 días por semana</option>
											<option value='Todos los días'>Todos los días</option>
											<option value='Solo los fines de semana'>Solo los fines de semana</option>
											<option value='Nunca'>Nunca</option>
										</select>
									</div>
								</div>
								}
							</div>

							<div style={{marginTop:50}}>
								<h5>¿Tienes algún medico de confianza?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='simedico'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='simedico' name='medico_de_confianza' checked={data.medico_de_confianza===1} onChange={(e)=>setData({...data, medico_de_confianza:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='nomedico'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='nomedico' name='medico_de_confianza' checked={data.medico_de_confianza===0} onChange={(e)=>setData({...data, medico_de_confianza:0})} required/>
									</div>
								</div>
							</div>

							<div style={{marginTop:50}}>
								<h5>¿Te gustaría ser Donador de Órganos?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='sidonador'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='sidonador' name='megustaria_ser_donador' checked={data.megustaria_ser_donador===1} onChange={(e)=>setData({...data, megustaria_ser_donador:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='nodonador'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='nodonador' name='megustaria_ser_donador' checked={data.megustaria_ser_donador===0} onChange={(e)=>setData({...data, megustaria_ser_donador:0})} required/>
									</div>
								</div>
							</div>
					
							<div style={{marginTop:50}}>
								<h5>¿Tienes alguno de los siguientes padecimientos?</h5>
								<div style={{display:'flex', flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Diabetes</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,diabetes:e.target.checked }})} checked={data.tengo.diabetes}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Hipertensión</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,hipertension:e.target.checked }})} checked={data.tengo.hipertension}/>
									</div>
									<div style={{display:'flex', alignItems:'center',margin:10}}>
										<label style={{width:100}}>Asma</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,asma:e.target.checked }})} checked={data.tengo.asma}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Colesterol Alto</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,colesterol:e.target.checked }})} checked={data.tengo.colesterol}/>
									</div>
									<div style={{display:'flex', alignItems:'center',margin:10}}>
										<label style={{width:100}}>Depresión</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,depresion:e.target.checked }})} checked={data.tengo.depresion}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Cáncer</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,cancer:e.target.checked }})} checked={data.tengo.cancer}/>
									</div>
		
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Ninguna</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,ninguna:e.target.checked }})} checked={data.tengo.ninguna}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Otra</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, tengo:{...data.tengo,otraB:e.target.checked }})} checked={data.tengo.otraB}/>
									</div>
							
								</div>
							</div>
							{data.tengo.otraB&&
							<div style={{marginTop:40}}>
								<label htmlFor='nombre'>Favor de mencionarla:</label>
								<input value={data.tengo.otra} type='text' onChange={(e)=>setData({...data, tengo:{...data.tengo,otra:e.target.value }})}/>
							</div>
							}

							<div style={{marginTop:50}}>
								<h5>¿Tienes algún daño en tu cuerpo?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='dañosi'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='dañosi' name='daño_en_cuerpo' checked={data.daño_en_cuerpo===1} onChange={(e)=>setData({...data, daño_en_cuerpo:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='dañono'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='dañono' name='daño_en_cuerpo' checked={data.daño_en_cuerpo===0} onChange={(e)=>setData({...data, daño_en_cuerpo:0})} required/>
									</div>
								</div>
								{data.daño_en_cuerpo===1&&
								<div style={{display:'block'}}>
									<label htmlFor='nombre'>Favor de mensionar dónde:</label>
									<input value={data.daño_en_cuerpo_descripcion} type='text' onChange={(e)=>setData({...data, daño_en_cuerpo_descripcion:e.target.value})} required/>
								</div>
								}
							</div>

							<div style={{marginTop:50}}>
								<h5>¿Tienes alguna Alergia?</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='alergiasi'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='alergiasi' name='alergia' checked={data.alergia===1} onChange={(e)=>setData({...data, alergia:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='alergiano'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='alergiano' name='alergia' checked={data.alergia===0} onChange={(e)=>setData({...data, alergia:0})} required/>
									</div>
								</div>
								{data.alergia===1&&
								<div style={{display:'block'}}>
									<label htmlFor='nombre'>Favor de mensionarla(s):</label>
									<input value={data.alergia_descripcion} type='text' onChange={(e)=>setData({...data, alergia_descripcion:e.target.value})} required/>
								</div>
								}
							</div>
						
							<div style={{marginTop:50}}>
								<h5>¿Padeciste COVID?</h5>
								<div style={{display:'flex'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label>Sí</label>
										<input style={{width:100}} type='radio' name='covid' checked={data.tuvecovid===true} onChange={(e)=>setData({...data, tuvecovid:true})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label>No</label>
										<input style={{width:100}} type='radio' name='covid' checked={data.tuvecovid===false} onChange={(e)=>setData({...data, tuvecovid:false})} required/>
									</div>
								</div>
								{data.tuvecovid&&
								<div style={{display:'block'}}>
									<div style={{marginRight:5, width:'100%'}}>
										<label htmlFor='' style={{display:'block'}}>¿Hace cuánto tiempo?</label>
										<select style={{width:'100%',height:44, fontSize:20}} value={data.tuvecovid_durante} onChange={(e)=>setData({...data, tuvecovid_durante:e.target.value})} required>
											<option value=''>Seleccione un opción</option>
											<option value='Menos de 3 Meses'>Menos de 3 Meses</option>
											<option value='Menos de 6 Meses'>Menos de 6 Meses</option>
											<option value='Más de 6 Meses'>Más de 6 Meses</option>
										</select>
									</div>
								</div>
								}
							</div>

							<div style={{marginTop:50}}>
								<h5> Selecciona los padecimientos que tienen o tuvieron tus padres.</h5>
								<div style={{display:'flex', flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Diabetes</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,diabetes:e.target.checked,ninguna:false }})} checked={data.padrescon.diabetes}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Hipertensión</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,hipertension:e.target.checked,ninguna:false }})} checked={data.padrescon.hipertension}/>
									</div>
									<div style={{display:'flex', alignItems:'center',margin:10}}>
										<label style={{width:100}}>Asma</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,asma:e.target.checked,ninguna:false }})} checked={data.padrescon.asma}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Colesterol Alto</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,colesterol:e.target.checked,ninguna:false }})} checked={data.padrescon.colesterol}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Depresión</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,depresion:e.target.checked,ninguna:false }})} checked={data.padrescon.depresion}/>
									</div>
									<div style={{display:'flex', alignItems:'center', margin:10}}>
										<label style={{width:100}}>Cáncer</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,cancer:e.target.checked,ninguna:false }})} checked={data.padrescon.cancer}/>
									</div>
									<div style={{display:'flex', alignItems:'center',margin:10}}>
										<label style={{width:100}}>Ninguna</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...padrescon,ninguna:e.target.checked }})} checked={data.padrescon.ninguna}/>
									</div>
									<div style={{display:'flex', alignItems:'center',margin:10}}>
										<label style={{width:100}}>Otra</label>
										<input style={{width:60}} type='checkbox' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,otraB:e.target.checked }})} checked={data.padrescon.otraB}/>
									</div>
								</div>
							</div>
					
							{data.padrescon.otraB&&
							<div style={{marginTop:40}}>
								<label htmlFor='nombre'>Favor de mencionarla:</label>
								<input value={data.padrescon.otra} type='text' onChange={(e)=>setData({...data, padrescon:{...data.padrescon,otra:e.target.value }})}/>
							</div>
							}

							<div style={{marginTop:50}}>
								<label>¿Cuánto invertirías en tu salud mensualmente?</label>
								<h4>${data.inversion_para_mi_salud*50} MXN</h4>
								<input type='range' value={data.inversion_para_mi_salud} onChange={e=>setData({...data,inversion_para_mi_salud:e.target.value})} required/>
							</div>

							<div style={{marginTop:50}}>
								<label>¿Qué medicamento te gustaría que fuera más barato?(Favor de mencionarlo,¿Por qué? ):</label>
								<textarea style={{width:'100%', height:100, fontSize:18}} type='text' value={data.megustaria_economizar_medicamento} onChange={(e)=>setData({...data, megustaria_economizar_medicamento:e.target.value})} />
							</div>

							<div style={{marginTop:50}}>
								<label>¿Qué te gustaría mejorar de tu salud (opcional):</label>
								<textarea style={{width:'100%', height:100, fontSize:18}} type='text' value={data.megustariamejorar} onChange={(e)=>setData({...data, megustariamejorar:e.target.value})} />
							</div>

							{/*{Campaña brigada}*/}
							<div style={{marginTop:50}}>
								<h5>Valoración</h5>
							</div>
							<div style={{marginTop:30}}>
								<label>peso en kilos(ejem: 61.3):</label>
								<input value={brigadaInputs.peso} type='number' onChange={(e)=>setBrigadaInputs({...brigadaInputs, peso:e.target.value })}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Medida cintura:</label>
								<input value={brigadaInputs.medida_cintura} type='number' onChange={(e)=>setBrigadaInputs({...brigadaInputs, medida_cintura:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Presión arterial:</label>
								<input value={brigadaInputs.presion_arterial} type='text' onChange={(e)=>setBrigadaInputs({...brigadaInputs, presion_arterial:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Saturación de sangre:</label>
								<input value={brigadaInputs.saturacion_sangre} type='number' onChange={(e)=>setBrigadaInputs({...brigadaInputs, saturacion_sangre:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Glucosa sanguínea:</label>
								<input value={brigadaInputs.glucosa_sanguinea} type='number' onChange={(e)=>setBrigadaInputs({...brigadaInputs, glucosa_sanguinea:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Masa corporal:</label>
								<input value={brigadaInputs.masa_corporal} type='number' onChange={(e)=>setBrigadaInputs({...brigadaInputs, masa_corporal:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Frecuencia cardiaca:</label>
								<input value={brigadaInputs.frecuencia_cardiaca} type='number' onChange={(e)=>setBrigadaInputs({...brigadaInputs, frecuencia_cardiaca:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Temperatura:</label>
								<input value={brigadaInputs.temperatura} type='number' onChange={(e)=>setBrigadaInputs({...brigadaInputs, temperatura:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Estatus pulmones:</label>
								<input value={brigadaInputs.estatus_pulmones} type='text' onChange={(e)=>setBrigadaInputs({...brigadaInputs, estatus_pulmones:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Inspección física:</label>
								<input value={brigadaInputs.inspeccion_fisica} type='text' onChange={(e)=>setBrigadaInputs({...brigadaInputs, inspeccion_fisica:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Adicciones:</label>
								<input value={brigadaInputs.adicciones} type='text' onChange={(e)=>setBrigadaInputs({...brigadaInputs, adicciones:e.target.value})}/>
							</div>
							<div style={{marginTop:30}}>
								<label>Depresión:</label>
								<input value={brigadaInputs.depresion} type='text' onChange={(e)=>setBrigadaInputs({...brigadaInputs, depresion:e.target.value})}/>
							</div>
							<div style={{marginTop:50}}>
								<h5>ESFERA HORMONAL</h5>
							</div>
							<div style={{textAlign:'center'}}>
								<h5>Equilibrio tiroideo</h5>
							</div>
							<div style={{marginTop:50}}>
								<h5>Siento cansancio todo el día en especial al despertar</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-input-siento-cansancio-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-input-siento-cansancio-si' name='brigada-input-siento-cansancio' checked={brigadaInputs.siento_cansancio_todo_el_dia===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, siento_cansancio_todo_el_dia:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-input-siento-cansancio-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-input-siento-cansancio-no' name='brigada-input-siento-cansancio' checked={brigadaInputs.siento_cansancio_todo_el_dia===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, siento_cansancio_todo_el_dia:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Tolero mal los cambios de temperatura, sobre todo el frío</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tolero-cambios-temperatura-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tolero-cambios-temperatura-si' name='brigada-tolero-cambios-temperatura' checked={brigadaInputs.tolero_mal_los_cambios_de_temperatura===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, tolero_mal_los_cambios_de_temperatura:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tolero-cambios-temperatura-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tolero-cambios-temperatura-no' name='brigada-tolero-cambios-temperatura' checked={brigadaInputs.tolero_mal_los_cambios_de_temperatura===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, tolero_mal_los_cambios_de_temperatura:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Estoy engordando y/o cada vez me cuesta más adelgazar</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-estoy-engordando-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-estoy-engordando-si' name='brigada-estoy-engordando' checked={brigadaInputs.estoy_engordando===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, estoy_engordando:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-estoy-engordando-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-estoy-engordando-no' name='brigada-estoy-engordando' checked={brigadaInputs.estoy_engordando===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, estoy_engordando:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Perdida de cabello, sequedad y uñas quebradizas</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-perdida-cabello-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-perdida-cabello-si' name='brigada-perdida-cabello' checked={brigadaInputs.perdida_de_cabello===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, perdida_de_cabello:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-perdida-cabello-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-perdida-cabello-no' name='brigada-perdida-cabello' checked={brigadaInputs.perdida_de_cabello===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, perdida_de_cabello:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Soy mujer y he empezado con periodos menstruales abundantes o irregulares</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-periodos-irregulares-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-periodos-irregulares-si' name='brigada-periodos-irregulares' checked={brigadaInputs.periodos_menstruales_abundantes===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, periodos_menstruales_abundantes:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-periodos-irregulares-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-periodos-irregulares-no' name='brigada-periodos-irregulares' checked={brigadaInputs.periodos_menstruales_abundantes===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, periodos_menstruales_abundantes:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>De repente mis heces se han vuelto duras y/o tengo estreñimiento</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-heces-duras-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-heces-duras-si' name='brigada-heces-duras' checked={brigadaInputs.heces_duras===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, heces_duras:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-heces-duras-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-heces-duras-no' name='brigada-heces-duras' checked={brigadaInputs.heces_duras===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, heces_duras:0})} required/>
									</div>
								</div>
							</div>

							{data.genero==='Mujer'&&
							<>
							<div style={{textAlign:'center',marginTop:150}}>
								<h5>Equilibrio de las hormonas sexuales femeninas</h5>
							</div>
							<div style={{marginTop:50}}>
								<h5>Antes de la menstruación, retengo mucho líquido y me hincho mucho</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-me-hincho-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-me-hincho-si' name='brigada-me-hincho' checked={brigadaInputs.antes_menstruar_retengo_liquidos===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, antes_menstruar_retengo_liquidos:1})} />
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-me-hincho-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-me-hincho-no' name='brigada-me-hincho' checked={brigadaInputs.antes_menstruar_retengo_liquidos===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, antes_menstruar_retengo_liquidos:0})} />
									</div>
								</div>
							</div>
							<div style={{marginTop:50}}>
								<h5>La semana anterior a la menstruación la paso mal (Hinchazón, compulsiones alimentarias)</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-semana-anterior-mal-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-semana-anterior-mal-si' name='brigada-semana-anterior-mal' checked={brigadaInputs.semana_anterior_la_paso_mal===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, semana_anterior_la_paso_mal:1})} />
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-semana-anterior-mal-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-semana-anterior-mal-no' name='brigada-semana-anterior-mal' checked={brigadaInputs.semana_anterior_la_paso_mal===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, semana_anterior_la_paso_mal:0})} />
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Mis periodos son dolorosos y/o abundantes</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-periodos-abundantes-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-periodos-abundantes-si' name='brigada-periodos-abundantes' checked={brigadaInputs.periodos_dolorosos_abundantes===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, periodos_dolorosos_abundantes:1})} />
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-periodos-abundantes-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-periodos-abundantes-no' name='brigada-periodos-abundantes' checked={brigadaInputs.periodos_dolorosos_abundantes===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, periodos_dolorosos_abundantes:0})} />
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Mis senos son sensibles y/o tienen quistes (Adenomas mamarios...)</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-senos-sensibles-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-senos-sensibles-si' name='brigada-senos-sensibles' checked={brigadaInputs.senos_sensibles===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, senos_sensibles:1})} />
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-senos-sensibles-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-senos-sensibles-no' name='brigada-senos-sensibles' checked={brigadaInputs.senos_sensibles===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, senos_sensibles:0})} />
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Tengo ciclos irregulares, cortos o demasiado largos</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-ciclos-irregulares-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-ciclos-irregulares-si' name='brigada-ciclos-irregulares' checked={brigadaInputs.ciclos_irregulares===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, ciclos_irregulares:1})} />
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-ciclos-irregulares-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-ciclos-irregulares-no' name='brigada-ciclos-irregulares' checked={brigadaInputs.ciclos_irregulares===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, ciclos_irregulares:0})} />
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Estoy nerviosa, lloro con facilidad, duermo mal</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-estoy-nerviosa-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-estoy-nerviosa-si' name='brigada-estoy-nerviosa' checked={brigadaInputs.estoy_nerviosa_lloro===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, estoy_nerviosa_lloro:1})} />
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-estoy-nerviosa-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-estoy-nerviosa-no' name='brigada-estoy-nerviosa' checked={brigadaInputs.estoy_nerviosa_lloro===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, estoy_nerviosa_lloro:0})} />
									</div>
								</div>
							</div>
							</>
							}


							<div style={{marginTop:50}}>
								<h5>ESFERA DE NUTRIENTES FUNCIONALES</h5>
							</div>
							<div style={{textAlign:'center'}}>
								<h5>Equilibrio acido-base</h5>
							</div>
							<div style={{marginTop:50}}>
								<h5>Tengo ardor de estomago y/o reflujo gastroesofágico</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-reflujo-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-reflujo-si' name='brigada-tengo-reflujo' checked={brigadaInputs.ardor_estomago===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, ardor_estomago:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-reflujo-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-reflujo-no' name='brigada-tengo-reflujo' checked={brigadaInputs.ardor_estomago===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, ardor_estomago:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>A menudo tengo contracturas, calambres, o espasmos musculares</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-calambres-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-calambres-si' name='brigada-tengo-calambres' checked={brigadaInputs.contracturas_calambres_espasmos===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, contracturas_calambres_espasmos:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-calambres-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-calambres-no' name='brigada-tengo-calambres' checked={brigadaInputs.contracturas_calambres_espasmos===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, contracturas_calambres_espasmos:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Tengo inflamación, artrosis, artritis, tendinitis, dolores en el cuerpo</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-inflamacion-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-inflamacion-si' name='brigada-tengo-inflamacion' checked={brigadaInputs.inflamacion_artrosis_artritis_tendinitis_dolores===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, inflamacion_artrosis_artritis_tendinitis_dolores:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-inflamacion-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-inflamacion-no' name='brigada-tengo-inflamacion' checked={brigadaInputs.inflamacion_artrosis_artritis_tendinitis_dolores===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, inflamacion_artrosis_artritis_tendinitis_dolores:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>ESFERA HEPATO-DIGESTIVA</h5>
							</div>
							<div style={{textAlign:'center'}}>
								<h5>Subesferas hepática</h5>
							</div>


							<div style={{marginTop:50}}>
								<h5>Tengo la lengua pastosa, mal aliento y/o la tez amarillenta</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-lengua-pastosa-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-lengua-pastosa-si' name='brigada-lengua-pastosa' checked={brigadaInputs.lengua_pastosa_mal_aliento===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, lengua_pastosa_mal_aliento:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-lengua-pastosa-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-lengua-pastosa-no' name='brigada-lengua-pastosa' checked={brigadaInputs.lengua_pastosa_mal_aliento===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, lengua_pastosa_mal_aliento:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Soy sensible a los olores, especialmente por la mañana cuando me levanto y/o tengo nauseas</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-olor-sensible-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-olor-sensible-si' name='brigada-olor-sensible' checked={brigadaInputs.sensible_olores===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, sensible_olores:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-olor-sensible-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-olor-sensible-no' name='brigada-olor-sensible' checked={brigadaInputs.sensible_olores===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, sensible_olores:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Me cuesta digerir comidas pesadas o grasientas/o tolero muy mal el alcohol</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-cuesta-digerir-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-cuesta-digerir-si' name='brigada-cuesta-digerir' checked={brigadaInputs.cuesta_digerir_pesado===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, cuesta_digerir_pesado:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-cuesta-digerir-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-cuesta-digerir-no' name='brigada-cuesta-digerir' checked={brigadaInputs.cuesta_digerir_pesado===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, cuesta_digerir_pesado:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Tengo una somnolencia irrefrenable tras comer y cenar</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-somnolencia-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-somnolencia-si' name='brigada-somnolencia' checked={brigadaInputs.somnolencia_tras_comer===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, somnolencia_tras_comer:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-somnolencia-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-somnolencia-no' name='brigada-somnolencia' checked={brigadaInputs.somnolencia_tras_comer===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, somnolencia_tras_comer:0})} required/>
									</div>
								</div>
							</div>



							<div style={{marginTop:50}}>
								<h5>Con frecuencia tengo dolores de cabeza o migrañas</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-dolor-cabeza-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-dolor-cabeza-si' name='brigada-dolor-cabeza' checked={brigadaInputs.dolor_cabeza_frecuente===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, dolor_cabeza_frecuente:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-dolor-cabeza-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-dolor-cabeza-no' name='brigada-dolor-cabeza' checked={brigadaInputs.dolor_cabeza_frecuente===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, dolor_cabeza_frecuente:0})} required/>
									</div>
								</div>
							</div>

							<div style={{marginTop:50}}>
								<h5>Estoy expuesto/a a productos químicos (Pinturas, productos para el hogar, peluquería, etc.)</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-exposicion-quimicos-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-exposicion-quimicos-si' name='brigada-exposicion-quimicos' checked={brigadaInputs.exposicion_quimicos===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, exposicion_quimicos:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-exposicion-quimicos-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-exposicion-quimicos-no' name='brigada-exposicion-quimicos' checked={brigadaInputs.exposicion_quimicos===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, exposicion_quimicos:0})} required/>
									</div>
								</div>
							</div>
							<div style={{textAlign:'center', marginTop:150}}>
								<h5>Subesfera intestinal</h5>
							</div>


							<div style={{marginTop:50}}>
								<h5>Me siento hinchado/a y/o me noto a menudo la tripa llena de gases</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tripa-de-gases-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tripa-de-gases-si' name='brigada-tripa-de-gases' checked={brigadaInputs.hinchado_lleno_de_gases===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, hinchado_lleno_de_gases:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tripa-de-gases-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tripa-de-gases-no' name='brigada-tripa-de-gases' checked={brigadaInputs.hinchado_lleno_de_gases===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, hinchado_lleno_de_gases:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Tengo dolores intestinales, cólicos o colitis</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-colitis-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-colitis-si' name='brigada-tengo-colitis' checked={brigadaInputs.dolores_intestinales_colicos_colitis===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, dolores_intestinales_colicos_colitis:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-colitis-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-colitis-no' name='brigada-tengo-colitis' checked={brigadaInputs.dolores_intestinales_colicos_colitis===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, dolores_intestinales_colicos_colitis:0})} required/>
									</div>
								</div>
							</div>


							<div style={{marginTop:50}}>
								<h5>Tengo diarrea o estreñimiento o voy altenando diarrea/estreñimiento</h5>
								<div style={{display:'flex',flexWrap: 'wrap'}}>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-diarrea-si'>Sí</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-diarrea-si' name='brigada-tengo-diarrea' checked={brigadaInputs.diarrea_estreñimiento===1} onChange={(e)=>setBrigadaInputs({...brigadaInputs, diarrea_estreñimiento:1})} required/>
									</div>
									<div style={{display:'flex', alignItems:'center'}}>
										<label style={{width:80,margin:0}} htmlFor='brigada-tengo-diarrea-no'>No</label>
										<input style={{width:30, marginLeft:10, marginRight:30}} type='radio' id='brigada-tengo-diarrea-no' name='brigada-tengo-diarrea' checked={brigadaInputs.diarrea_estreñimiento===0} onChange={(e)=>setBrigadaInputs({...brigadaInputs, diarrea_estreñimiento:0})} required/>
									</div>
								</div>
							</div>
						</div>
						{loader?<h5>Cargando...</h5>:<button onClick={()=>console.log(brigadaInputs)} type='submit' style={{backgroundColor:'#2B3663', width:163, height:44, color:'white',border: '1.5px solid #2B3663'}}>Enviar</button>}
					</div>
				</form>
			</div>
		</center>
	</div>
	)
}

export default Brigada
