import React from 'react';
import './noticias.css';
import CardNoticia from './CardNoticia';
import TituloSection from '../TituloSection';
import noticias from './noticias';

const Noticias = () => {

	return (
		<div id='noticias' className='container-fluid my-4'>
		<div className='rowi p-4'>
			<TituloSection titulo='NOTICIAS' tituloColor='#06294A' colorLine='#24B9B6' />
			<div className='row m-0 p-0'>
			{noticias.map((n,i)=>
				<div key={i} className='col-12 col-md-4 m-0 p-2g' >
					<CardNoticia id={n.id} titulo={n.titulo} description={n.descripcionBreve} images={[n.portada]}/>
				</div>
			)}
			</div>
		</div>
		</div>
		);
}

export default Noticias
