import React, { Component } from 'react';
import './header.css';
import Btncustom from '../Btncustom'; 

const Header = (props) => {

	const{title, subtitle, text, textcolor}= props

	return (
		<div id='inicio'>
			<div className='cont-pallalax m-0 p-0'>
				<div className='m-0 p-0' style={{width:'100%'}}>
					<div className='col-md-6 cont-text'>
						<div className='title'>{title}</div>
						<div className='subtitle'>{subtitle}</div>
						<p className=''>{text}</p>
					<a href='#nosotros' style={{display:'block', background:'#06294A', width:100, padding:10, textAlign:'center',color:'white', borderRadius:5, textDecoration:'none'}}>Ver más</a>
					</div>
				</div>
				<div> 
				</div>
			</div>
		</div>
	);
}

export default Header;
