import React from 'react';
import { Link } from 'react-router-dom';
import Btncustom from '../../Btncustom';

const Cardblog = ({id,title,description,img,url,link}) => {

	return (
		<div className='card card-blog' style={{height:450}}>
			<img alt={title} src={img} alt='image stock' height='250' style={{objectFit:'contain'}}/>
			<div className='card-body p-0'>
				<h5 className='card-title my-2' style={{color:'#06294A'}}>{title}</h5>
				<p className='card-text'>{description}</p>
				<div className='text-right'>
					<Link to={'/campaña/'+link} style={{float:'right',display:'block', background:'#06294A', width:100, padding:10, textAlign:'center',color:'white', borderRadius:5, textDecoration:'none'}}>Ver más</Link>
				</div>
			</div>
		</div>
	);
};

export default Cardblog;
