import React from 'react'
import { Link } from 'react-router-dom'
import cuadro1 from '../../assets/rudosvstecnicos/fondos/Campaña-Vampiro-01.jpg'
import cuadro2 from '../../assets/rudosvstecnicos/fondos/Campaña-Vampiro-02.jpg'
import fondoAlianzas from '../../assets/unidosvsdiabetes/Fondos/nuestras-alianzas.jpg'
import a1 from '../../assets/unidosvsdiabetes/img/Logos/01.jpg'
import anps from '../../assets/rudosvstecnicos/logos/ANPS.png'
import vampiro from '../../assets/rudosvstecnicos/logos/Vampiro.jpg'
import unidosvsdiabetes from '../../assets/rudosvstecnicos/logos/Unidos-vs-Diabetes.jpg'
import rudos from '../../assets/rudosvstecnicos/logos/rudos.png'
import aqua from '../../assets/rudosvstecnicos/logos/Aqua-Deyun.png'

const UnidosvsDiabetes = () => {
	return(
		<div id='rudosvstecnicos'>
		<div className='section1-rudos' style={{backgroundImage:'url('+cuadro1+')'}}>
					<div className='m-row' style={{color:'grey', fontSize:15, display:'flex', flexWrap: 'wrap',justifyContent:'right' }}>
						<div className='m-col' style={{marginBottom:10}}>
							<img alt='anps' src={anps} className='anps-responsive'/>
						</div>
						<div className='m-col' style={{marginBottom:10}}>
							<img alt='vampiro canadiense' src={vampiro} className='vampiro-responsive'/>
						</div>
						<div className='m-col' style={{marginBottom:10}}>
							<img alt='unidos contra la diabetes' src={unidosvsdiabetes} className='unidos-responsive'/>
						</div>
					</div>
					<div>
						<img alt='rudos' src={rudos} className='rudos-responsive'/>
					</div>
					<div>
						<img alt='aqua deyun' src={aqua} width='200' style={{position:'absolute', bottom:100, right:10, zIndex:0}}/>
					</div>


		</div>

		<div className='section2-rudos' style={{backgroundImage:'url('+cuadro2+')'}}>

				<div className='poligon-1'>
					<p><span>LUCHEMOS MANO A MANO VS DIABETES, </span> no importa si eres 'rudo o técnico', apliquemos llaves, contrallaves, y castigos a la segunda causa de muerte en México.</p>
				</div>
				<div className='poligon-2'>
					<p>SI PARTICIPAS IRÉ HASTA TU CASA A CONOCERTE Y PREPARARNOS PARA ¡LUCHAR JUNTOS VS LA DIABETES!</p>
				</div>



				<div style={{marginLeft:30}}>
					<h4 style={{color:'white', fontSize:18, marginLeft:30}}>Es importante cumplir con los siguientes pasos:</h4>
					<div className='round-1'> Round 1: Regístrate en el siguiente link: 
						<a href='https://www.anpsmexico.org/registrate/apoyo' target='_blank' style={{display:'block'}}>http://anpsmexico.org/registrate/apoyo</a>
					</div>
					<div className='round-2'>Round 2: Da like a las páginas de Facebook:
						<a href='https://facebook.com/anpsmexico' style={{color:'white'}} target='_blank'>@anpsmexico, </a>
						<a href='https://facebook.com/AquaDeyunmx' style={{color:'white'}} target='_blank'>@AquaDeyunmx y </a>
						<a href='https://facebook.com/vampirovudu' style={{color:'white'}} target='_blank'>@vampirovudu</a>
					</div>
					<div className='round-3' style={{color:'black', background:'grey', padding:20, fontSize:20, fontWeight:'bold'}}>Round 3: Adquiere tu Kit Aqua Deyun en el siguiente link: <a href='https://aquadeyun.pay.kangoru.mx' target='_blank'>http://aquadeyun.pay.kangoru.mx</a></div>
					<div style={{minHeight:230,color:'white', padding:30, fontSize:28}}>
						Anunciaré a los 5 ganadores (en una dinámica aleatoria) en mi página oficial de Facebook, <strong> NO LO OLVIDES, JUNTOS MEJORAREMOS TU CALIDAD DE VIDA.</strong>
					</div>
				</div>
				<div className='m-row'>
				<div className='m-col m8'>
				<div style={{display:'inline-block'}}>
					<img alt='aqua deyun' src={aqua} width='300' />
					
				</div>
				</div>
				<div className='m-col m4'>
				<div className='m-row' style={{color:'grey', fontSize:15, display:'flex', flexWrap: 'wrap',justifyContent:'right'}}>
					<div className='m-col' style={{marginBottom:10}}>
						<img alt='anps' src={anps} height='70' className='anps-responsive'/>
					</div>
					<div className='m-col' style={{marginBottom:10}}>
						<img alt='vampiro canadiense' src={vampiro} height='70' className='vampiro-responsive'/>
					</div>
					<div className='m-col' style={{marginBottom:10}}>
						<img alt='unidos contra la diabetes' src={unidosvsdiabetes} height='70' className='unidos-responsive'/>
					</div>
				</div>
				</div>
				</div>
		</div>
		</div>
	)
	
}

export default UnidosvsDiabetes
