const campañas = [
	{
		id:1,
		link:'protegeaquientecuida',
		titulo:'Protege a quien te cuida',
		descripcion:'Existen héroes que todos los días ponen en riesgo su salud por todos nosotros, lamentablemente no cuentan con los insumos básicos necesarios para el día a día, por esto también necesitan nuestra ayuda para seguir con esta gran labor. Desarrollamos los medios e iniciativas encaminadas a fomentar la donación de insumos y dar el apoyo necesario para todo el personal que integra el cuerpo médico, llámese enfermeras, médicos, paramédicos y camilleros. ',
		descripcionBreve:'Existen héroes que todos los días ponen en riesgo su salud por todos nosotros,...',
		portada:'/resources/logos/Protege-a-quien-te-cuida.png',
		imagenes:[{titulo:'',url:'/resources/logos/Protege-a-quien-te-cuida.png'}],
		videos:[]
	},
	{
		id:2,
		link:'cambiandovidas',
		titulo:'Cambiando vidas',
		descripcion:'Esta campaña se inició ya que para nosotros es sumamente importante y no hay nada más satisfactorio que contribuir con la felicidad de las personas, por lo mismo buscamos cambiar la vida de aquellas que por nacimiento o por algún motivo o circunstancia hayan perdido alguna extremidad y que lamentablemente no tienen los recursos necesarios para solucionar su problema, queremos ayudar para que tengan una mejor calidad de vida, por lo mismo donamos prótesis de extremidades y prótesis realistas, para que puedan facilitar su adaptación a la sociedad y así mismo nos encargamos de dar seguimiento a la rehabilitación, siendo nuestro objetivo principal impactar de manera positiva la vida de las personas.',
		descripcionBreve:'Esta campaña se inició ya que para nosotros es sumamente importante y no hay nada más satisfactorio que contribuir con la felicidad de las personas, por lo mismo buscamos cambiar la vida...',
		portada:'/resources/logos/Cambiando-Vidas.png',
		imagenes:[{titulo:'',url:'/resources/logos/Cambiando-Vidas.png'}],
		videos:[]
	},
	{
		id:3,
		link:'unidosvsdiabetes',
		titulo:'UnidosVSdiabetes',
		descripcion:'Atendiendo al llamado de la ONU, para cumplir con los objetivos de desarrollo sostenible, nos sumamos para hacer todo lo posible en una de las problemáticas más importantes que hay en el mundo, LA DIABETES, buscamos mejorar la calidad de vida de las personas que tienen este padecimiento, creando educación y conciencia.  La diabetes es la segunda causa de muerte a nivel nacional y es derivada a un mal control o mal tratamiento de la enfermedad, una de las problemáticas principales es la falta de educación, por lo mismo hacemos capsulas informativas y enlaces con entes especialistas que nos aporten sus conocimientos para tener la información adecuada. Nosotros enlazamos a doctores, asociaciones, organizaciones y colegios de medicina, para compartir el conocimiento de cada una de sus áreas, para crear conciencia y educación con respecto a las mejores recomendaciones, tratamientos y hábitos que deben de seguir las personas con diabetes.',
		descripcionBreve:'Atendiendo al llamado de la ONU, para cumplir con los objetivos de desarrollo sostenible, nos sumamos para hacer todo lo posible en una de las problemáticas más importantes que hay en el mundo, LA DIABETES...',
		portada:'/resources/logos/Unidos-vs-Diabetes.png',
		imagenes:[{titulo:'',url:'/resources/logos/Unidos-vs-Diabetes.png'}],
		videos:[{titulo:'',url:'https://www.youtube.com/embed/i9VKZk7crx0'}]
	},
]

export default campañas
