import React from 'react';
import Card from './Card';
import TituloSection from '../TituloSection';
import './blog.css';
import Btncustom from '../Btncustom';
import Slider from 'react-slick';
import campañas from './campanas';

const Blog = () => {
	const settings = {
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: (
			<div className='m-0 p-0'>
				<div className='next-slick-arrow' style={{right:30,position:'absolute'}}>
					<img alt='next' src='/resources/next.png' className='mx-auto d-block color-flecha color' alt='...'/>
				</div>
			</div>
		),
		prevArrow: (
			<div className='m-0 p-0'>
				<div className='prev-slick-arrow' style={{left:26,position:'absolute'}} >
					<img alt='prev' src='/resources/prev.png' className='mx-auto d-block color-flecha color' alt='...'/>
				</div>
			</div>
		)
	};


	return (
		<div id='blog' className='container-fluid blog-container'>
			<div className='row p-4'>
				<div className='col-12 my-3'>
					<TituloSection titulo='Campañas' tituloColor='#FFFFFF' colorLine='#06294A' />
				</div>  
				<div className='col-12 mt-3 mb-5'>
					<div className='blog-cards-container p-3'>
						<div className='row p-3'>
							{campañas.map((c,i)=>
							<div key={i} className='col-12 col-md-4 p-3'>
								<Card title={c.titulo} description={c.descripcionBreve} img={c.portada} id={c.id} link={c.link}/>
							</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Blog;
