import React, { useState, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import axios from '../axios'

const Login = ({setAuth}) => {

	const [data, setData] = useState({email:'',password:''})
	const history = useHistory();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: '/admin' } };

	const login = (e) => {
		e.preventDefault()
		axios.post('/api/login',data).then(r=>{
			localStorage.setItem('access_token', 'Bearer '+r.data.api_key);
			axios.defaults.headers.common['Authorization'] = 'Bearer '+r.data.api_key
			setAuth(true)
			history.replace(from)
		}).catch(r=>alert(r))
	};

	return (
		<div style={{position:'absolute' ,top:'50%',left:'50%',transform: 'translate(-50%, -50%)'}}>
				<div style={{backgroundColor: 'rgba(255,255,255,.7)', padding:50}}>
				<form onSubmit={login}>
					<h3>Login</h3>
					<div>
						<label htmlFor='email'>Email</label>
						<input onChange={(e)=>setData({...data, email: e.target.value})} id='email' type='email' required/>
					</div>
					<div>
						<label htmlFor='password'>Password</label>
						<input onChange={(e)=>setData({...data, password: e.target.value})} id='password' type='password' required/>
					</div>
					<center>
						<button type='submit' style={{height:40, width:100, background:'black',color:'white', margin:10, border:0}}>Entrar</button>
					</center>
				</form>
				</div>
		</div>
	);
}

export default Login
