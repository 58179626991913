import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import noti from '../../assets/noti.png'
import contacto from '../../assets/contacto.png'
import anps from '../../assets/anps.png'
import quienes from '../../assets/quienesSomos.png'
import afiliados from '../../assets/afiliados.png'
import Menu from './MainHeader/Menu';

const NavUnidos = () => {

	let history = useHistory();
	const [navbarcolor, setNavbar] = useState(false)
	const styles = { color: '#ffffff' };

	useEffect(() => {
		changeBackground()
		window.addEventListener('scroll', changeBackground)
	})

	const changeBackground = () => { 
		if (window.scrollY >= 100)setNavbar(true)  
		else setNavbar(false)
	}

	return(
			<>
		<header className='container-fluid m-0 p-0 cont-menu' style={{zIndex:4,background: navbarcolor ? '#24415B' : 'linear-gradient(0deg, rgba(255, 255, 255, 0)0%, rgba(136, 135, 135, 0.3) 35%, rgba(0, 0, 0, .6)100%)' }}>
			<div className='row m-0 pl-3 pr-3'>
				<div className='col-md-10 m-0 p-0'>
			<div className='container-fluid m-0 p-0 '>
				<div className='container-xl p-0'>
					<div className='row m-0'>
						<nav className='navbar navbar-expand-lg navbar-dark'>
							<a className='navbar-brand logo' href='#inicio' onClick={()=>history.push('/')}>
								<img src='/resources/logo-color.png' className='mx-auto d-block efecto' alt='...'/>
							</a>
							<button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
								<span className='navbar-toggler-icon'></span>
							</button>
							<div className='collapse navbar-collapse' id='navbarSupportedContent'>
								<ul className='navbar-nav mr-auto' data-toggle='collapse' data-target='#navbarSupportedContent'>
									<li className={window.location.href.includes('section-1')?'nav-item active':'nav-item'}>
										<a href='#section-1' onClick={()=>history.push('/unidosvsdiabetes')} className='nav-link menu' style={styles}>
											QUÉ ES?
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('section-2')?'nav-item active':'nav-item'}>
										<a href='#section-2' onClick={()=>history.push('/unidosvsdiabetes')} className='nav-link menu' style={styles}>
											ALIANZAS
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('section-3')?'nav-item active':'nav-item'}>
										<a href='#section-3' onClick={()=>history.push('/unidosvsdiabetes')} className='nav-link menu' style={styles}>
											BENEFICIOS
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('section-4')?'nav-item active':'nav-item'}>
										<a href='#section-4' onClick={()=>history.push('/unidosvsdiabetes')} className='nav-link menu' style={styles}>
											REGÍSTRATE
											<div className='bordo'></div>
										</a>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</div>
				</div>
			</div>
		</header>
			</>
	)
}

export default NavUnidos
