import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import cuadro1 from '../../../assets/cuadro1.png'

const Registro = () => {
	return(
		<div id='registrate' style={{backgroundImage:'url(/resources/bg-header.jpg)',backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundAttachment:'fixed',paddingTop:50,paddingBottom:100, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.05)'}}>
		<center>
		<div style={{position:'relative',display:'block',paddingBottom:100, paddingTop:10}}>
			<div style={{margin:100,color:'#2B3663', fontSize:30, fontWeight:900}}>
				<div style={{display:'inline-block', padding:20}}>
					<div style={{display:'inline', borderBottom:'4px solid #25969F'}}>Reg</div>ístrate
				</div>
			</div>
			<Link to='/registrate/apoyo'>
				<div className='card2' style={{background:'rgba(255,255,255,.8)',color:'#2B3663',borderRadius:10,padding:'20px 10px 10px 10px',width:300, position:'relative', display:'inline-block', margin:10}}>
					<div style={{height:30,fontSize:16, fontWeight:'bold', textTransform:'uppercase'}}>Regístrate y recibe nuestros programas de apoyo</div>
					<p style={{textAlign:'justify', margin:'10px 0px 10px 0px', height:74, overflow:'auto'}}>Queremos promover la salud, regístrate como miembro para recibir los beneficios que la ANPS tiene para ti. ¡Por un México más Saludable!</p>
					<img src='https://www.bibliotecabrincar.org.ar/wp-content/uploads/2021/03/group-friends-gathering-together-scaled.jpg' alt='img' width='280' height='250'/>
				</div>
			</Link>
			<Link to='/registrate/aliado'>
				<div className='card2' style={{background:'rgba(255,255,255,.8)',color:'#2B3663',borderRadius:10,padding:'20px 10px 10px 10px',width:300, position:'relative', display:'inline-block', margin:10}}>
					<div style={{height:30,fontSize:16, fontWeight:'bold', textTransform:'uppercase'}}>Regístrate como aliado</div>
					<p style={{textAlign:'justify',margin:'10px 0px 10px 0px', height:74, overflow:'auto'}}>Ayudanos a promover la Salud, todos los esfuerzos suman, si tienes algún producto o servicio que pueda beneficiar a las personas, ¡Regístrate!</p>
					<img src='https://www.nasihatler.com/wp-content/uploads/2010/04/dostluk.jpg' alt='img' width='280' height='250'/>
				</div>
			</Link>
			<Link to='/registrate/brigada'>
				<div className='card2' style={{background:'rgba(255,255,255,.8)',color:'#2B3663',borderRadius:10,padding:'20px 10px 10px 10px',width:300, position:'relative', display:'inline-block', margin:10}}>
					<div style={{height:30,fontSize:16, fontWeight:'bold', textTransform:'uppercase'}}>Regístrate en la campaña brigada</div>
					<p style={{textAlign:'justify',margin:'10px 0px 10px 0px', height:74, overflow:'auto'}}>Entra y regístrate ahora </p>
					<img src='https://static4.abc.es/media/bienestar/2020/10/29/interaccion-humana-importancia-kTgH--620x349@abc.jpg' alt='img' width='280' height='250'/>
				</div>
			</Link>
		</div>
		</center>
		</div>
	)
}

export default Registro
