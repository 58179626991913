import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch, useParams, Redirect } from "react-router-dom";
import axios from '../axios'
import Dashboard from './dashboard'
import Login from './login'

const Admin = () => {

	const [auth, setAuth] = useState(false)
	const [loader, setLoader] = useState(true)
	const [user, setUser] = useState({})
	let match = useRouteMatch();

	useEffect(()=>{
		axios.get('/api/user').then(r=>{
			setAuth(true)
			setLoader(false)
			setUser(r.data)
		}).catch(r=>setLoader(false))

	},[])

	if(loader) return (
		<div style={{height:'100vh',display:'flex', alignItems:'center', justifyContent:'center'}}>CARGANDO...</div>
	)

	return(
		<Switch>
			<Route path={`${match.path}/login`}><Login setAuth={setAuth}/></Route>
			<Route path={match.path} render={({ location }) => auth ? <Dashboard user={user}/> : ( <Redirect to={{ pathname: `${match.path}/login`, state: { from: location } }} />)} />
		</Switch>
	)
}

export default Admin
