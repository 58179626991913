import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import cuadro1 from '../../assets/cuadro1.png'

const Privacidad = () => {

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return(
		<div id='registrate' style={{backgroundImage:'url('+cuadro1+')',backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundAttachment:'fixed',paddingTop:50,paddingBottom:100, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.05)'}}>
		<div style={{position:'relative',display:'block',paddingBottom:100, paddingTop:10}}>
			<div className='privacidad-container'>
				<div style={{padding:20, fontSize:30, fontWeight:900, textAlign:'center'}}>
					<div style={{display:'inline', borderBottom:'4px solid #25969F'}}>Av</div>iso de privacidad
				</div>
				<div>
					Fecha de la más reciente actualización: 09 de septiembre 2021.
				</div>
				<p>
					En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante, la "Ley"), ponemos a su disposición el presente aviso de privacidad integral (en adelante, “Aviso”), y al respecto le informamos de lo siguiente:
				</p>
				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>1. Responsable de la protección de los datos personales.</div>
				<div>
					<span className='bold'>ANPS – Alianza Nacional de Prevención y Salud</span> (en lo sucesivo la <span className='bold'>"Empresa"</span>), con domicilio para recibir notificaciones en Av. Lázaro Cárdenas No. 3609 Col. Jardín de San Ignacio C.P. 45040 Zapopan, Jalisco, México, es responsable del tratamiento y protección de los datos personales que recabemos de usted.
				</div>
				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>2. Información sobre nuestro oficial de privacidad y cómo contactarlo</div>
				<div>
					Nuestro oficial de privacidad es la persona al interior de nuestra organización que, en cumplimiento con el artículo 30 de la Ley, ha sido formalmente designado para: 
				</div>
				<ul>
					<li>Atender las solicitudes de los titulares para el ejercicio de sus derechos.</li>
					<li>Fomentar y velar por la protección de los datos personales que tenemos en nuestra posesión.</li>
				</ul>
				<p>
					Para todo lo relacionado con el tratamiento y protección de los datos personales, usted podrá contactarse con nuestro oficial de privacidad enviando un correo electrónico a la siguiente dirección: <a href='mailto:contacto@anpsmexico.org'>contacto@anpsmexico.org</a>
				</p>
				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>3. Datos personales que recabamos.</div>
				<p>Para realizar las finalidades señaladas en el numeral 4 (cuatro) del presente Aviso, recabaremos datos personales de las siguientes categorías: (i) Identificación; (ii); contacto y (iii) comportamiento de consumo. No recabaremos datos personales sensibles.</p>
				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>4. Finalidades del tratamiento de los datos personales recabados.</div>
				<div>
					Los datos personales que recabamos de usted los utilizaremos para las siguientes <span className='bold'>finalidades primarias</span>, las cuales son necesarias para proporcionarle el producto o servicio que solicita:	
					<ul>
						<li>Identificarlo y contactarlo.</li>
						<li>Hacerle válidos los beneficios que se le hayan ofrecido y usted solicite, así como descuentos o promociones.</li>
						<li>Atender sus solicitudes de postventa.</li>
						<li>Atender y dar seguimiento a sus quejas y sugerencias.</li>
					</ul>
				</div>
				<div>
					Adicionalmente, su información personal será utilizada con las siguientes <span className='bold'>finalidades secundarias</span>, las cuales no son necesarias para proporcionarle nuestros servicios, pero nos permiten brindarle un mejor servicio y desarrollar nuestros legítimos intereses comerciales:
					<ul>
						<li>Enviarle publicidad por cualquier medio.</li>
						<li>Ofrecerle beneficios de nuestros aliados.</li>
						<li>Proporcionarle descuentos con fines mercadotécnicos.</li>
						<li>Realizar encuestas para evaluar la calidad del servicio que le brindamos.</li>
						<li>Realizar todo tipo de actividades publicitarias, promocionales y mercadotécnicas.</li>
					</ul>
				</div>
				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>4.1 Negativa al tratamiento de sus datos personales para finalidades secundarias.</div>
				<p>En caso de que no desee que sus datos personales sean tratados para las finalidades secundarias mencionadas, o alguna(s) de ellas, puede negarnos su consentimiento desde este momento enviando su solicitud a nuestro oficial de privacidad, quien le indicará el procedimiento a seguir para ejercer su derecho. Su negativa en este sentido será motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>

				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>5. Transferencia de los datos personales.</div>
				<p>Para el cumplimiento de las finalidades señaladas en el numeral 4 (cuatro) del presente Aviso, sus datos personales podrán ser transferidos a las siguientes personas físicas o morales, sin que legalmente se requiera su consentimiento en términos del artículo 37 de la Ley:</p>
				<ul>
					<li>Sociedades nacionales y/o extranjeras pertenecientes a nuestro mismo grupo corporativo, que operen bajo nuestros mismos procesos y políticas internas, con la finalidad de cumplir con nuestra normativa y procesos.</li>
					<li>Autoridades, con la finalidad de cumplir con las disposiciones legales correspondientes, así como para el esclarecimiento de incidentes o ejercicio de algún derecho.</li>
					<li>En caso de actualizarse el supuesto que corresponda, cualquiera de las transferencias contempladas por el artículo 37 de la Ley.</li>
				</ul>
				<p>Asimismo, sus datos personales de identificación y contacto podrán ser transferidos a las siguientes personas morales, en cuyo caso sí requerimos que nos otorgue su consentimiento:</p>
				<ul>
					<li>Sociedades que son nuestras licenciantes, con la finalidad de cumplir con las condiciones contractuales de las licencias que se nos otorgaron, quienes podrán utilizarla para efectos promocionales, publicitarios, mercadotécnicos y de prospección comercial.</li>
				</ul>
				<p>Si usted no manifiesta su negativa para que realicemos esta transferencia, entenderemos que nos ha otorgado su consentimiento. Su negativa la puede manifestar desde este momento enviando un correo electrónico a nuestro oficial de privacidad, quien le indicará el procedimiento a seguir para ejercer su derecho.</p>
				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>6. Derechos ARCO.</div>
				<div>
					En los términos de la normativa aplicable, usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (<span className='bold'>A</span>cceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (<span className='bold'>R</span>ectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (<span className='bold'>C</span>ancelación); así como oponerse al uso de sus datos personales para fines específicos (<span className='bold'>O</span>posición). Estos derechos se conocen como derechos ARCO.
				</div>
				<p>Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través de un correo electrónico dirigido a nuestro oficial de privacidad, quien le informará sobre el procedimiento y requisitos para el ejercicio de esos derechos, plazos de respuesta, la forma en que haremos efectivo su derecho, y atenderá cualquier duda, queja o comentario que tenga al respecto.</p>

				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>7. Mecanismos y procedimiento para revocar su consentimiento.</div>
				<p>En los términos de la normativa aplicable, usted puede revocar el consentimiento que en su caso nos haya otorgado. Sin embargo, es importante que tenga en cuenta que no en todos los casos será procedente su solicitud o podremos concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.</p>
				<p>Para revocar su consentimiento deberá presentar su solicitud a través de correo electrónico dirigido a nuestro oficial de privacidad, quien le informará sobre el procedimiento y requisitos para el ejercicio de este derecho, plazos de respuesta, la forma en que haremos efectivo su derecho, y atenderá cualquier duda, queja o comentario que tenga al respecto.</p>

				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>8. Opciones con las que cuenta el titular para limitar el uso o divulgación de los datos personales.</div>
				<p>Además del procedimiento y ejercicio de los derechos contemplados en los numerales 6 (seis) y 7 (siete) del presente Aviso, usted puede limitar el uso o divulgación de sus datos personales seleccionando en cualquier momento la opción “unsuscribe” para cancelar la recepción de correos electrónicos que le enviemos con fines publicitarios, promocionales o mercadotécnicos, la cual se encuentra en todas las comunicaciones de dichos correos.</p>

				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>9. Uso de cookies y web beacons.</div>
				<p>No recabaremos datos personales a través de cookies, web beacons u otras tecnologías de rastreo.</p>

				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>10. Medidas de seguridad.</div>
				<p>La protección y buen uso de su información personal es muy importante para nuestra organización, por lo que contamos con medidas de seguridad físicas, técnicas y administrativas para protegerla contra daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado.</p>

				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>11. Modificaciones al presente Aviso.</div>
				<p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso atendiendo a novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de nuestros servicios o productos. Le daremos a conocer la versión actualizada a través de alguno o algunos de los siguientes medios:</p>
				<ul>
					<li>En nuestra página de Internet, la cual sugerimos visite frecuentemente.</li>
					<li>Se la podremos hacer llegar al último correo electrónico que nos haya proporcionado.</li>
				</ul>
				<div>
					El procedimiento con relación a la notificación vía internet es el siguiente: (i) Ingrese a nuestra página de Internet <a href='https://www.anpsmexico.org' target='_blank'>www.anpsmexico.org</a>. En caso de haber modificaciones o actualizaciones al presente Aviso, habrá una notificación que se lo haga saber; (ii) acceda a la liga del Aviso; (iii) ahí encontrará la versión vigente con la fecha de la más reciente actualización.
				</div>

				<div className='bold' style={{margin:'30px 0px 30px 0px'}}>12. Tratamiento indebido de los datos personales.</div>
				<div>
					Si usted considera que su derecho a la protección de datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI). Para más información, le sugerimos visitar su página oficial de Internet: <a href='http://www.inai.org.mx' target='_blank'>www.inai.org.mx</a>
				</div>

			</div>
		</div>
		</div>
	)
}

export default Privacidad
