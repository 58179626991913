import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import axios from './axios'

import Dashboard from './dashboard'
import Admin from './admin'

const App = () => {


	return (
		<Router>
			<Switch>
				<Route path='/adminv1'><Admin /></Route>
				<Route path='/'><Dashboard /></Route>
			</Switch>
		</Router>
	)
}

export default App;
