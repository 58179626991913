import React, { useState } from 'react';
import Btncustom from '../Btncustom';
import './contacto.css';
import { Link } from 'react-router-dom'
import axios from '../../axios'

 /**
	 * componente Contacto; seccion informitva de contacto con fomulario y enlaces a redes solciales, politicas, faqs etc...
	 * Pametros Recibidos
	 * dir:string
	 * phone:string 
	 * email: string
	 * nombre: string
	 * url: url tipo string
	 * icon: url de imagen tipo string
	 * 
	 */
const Contacto = ({dir,phone,email}) => {

	const [data, setData] = useState({correo:'', mensaje:''})

	const redes =[
			{
				nombre:'Facebook',
				url : 'https://facebook.com/AnpsMexico',
				icon: '/resources/face_icon.png'
			},
			{
				nombre:'Instagram',
				url : 'https://instagram.com/anpsmexico',
				icon: '/resources/insta_icon.png'
			},
			{
				nombre:'Twitter',
				url : 'https://twitter.com/anpsmexico',
				icon: '/resources/twitter_icon.png'
			},
			{
				nombre:'Youtube',
				url : 'https://youtube.com/anpsmexico',
				icon: '/resources/youtube_icon.png'
			}
		]
	const _contactar = (e) => {

		e.preventDefault()
		axios.post('/contacto',data)
		.then((r) => {
			console.log(r.data)
			alert('Gracias por ponerte en contacto con nosotros!')
			setData({correo:'', mensaje:''})
		})
		.catch((r)=>alert(r))
	}


	return (
		<div className='container-fluid contact-container'>
			<div className='row'>
				<div className='col-12 col-md-3 py-5 pl-5' style={{}}>
					<h4 className='contact-title'>Contacto</h4>
					<form onSubmit={_contactar}>
						<div className='form-group'>
							<label>Correo electrónico:</label>
							<input value={data.correo} onChange={(e)=>setData({...data, correo: e.target.value})} type='email' className='form-control contact-input' required/>
						</div>
						<div className='form-group'>
							<label>Mensaje:</label>
							<textarea value={data.mensaje} onChange={(e)=>setData({...data, mensaje: e.target.value})} className='form-control contact-input' rows='4' required/>
						</div>
						<button className='btn btn-primary' style={{background:'cyan', color:'black'}}>Enviar</button>
					</form>
				</div>
				
				<div className='col-sm-12 col-md-6 p-5'>
					<div className='row'>
						<div className='col'>
							<h4 className='contact-title'>Sobre Nosotros</h4>
							<ul className='p-0 contact-list'>
								<li className='d-flex my-3'>
									<div className='mr-2'>
										<img src='/resources/mark_icon.png' className='contact-icon' alt='address'/>
									</div>
									<span>{dir}</span>
								</li>
								<li className='d-flex my-3'>
									<div className='mr-2'>
										<img src='/resources/mail_icon.png' className='contact-icon' alt='email'/>
									</div>
									<a href={`mailto:${email}`} style={{color:'white'}}>{email}</a>
								</li>
								<li className='d-flex my-3'>
									<div className='mr-2'>
										<img src='/resources/call_icon.png' className='contact-icon' alt='call'/>
									</div>
									<span>{phone}</span> 
								</li>
							</ul>
							<Link to='/privacidad' style={{color:'white'}}>Aviso de privacidad</Link>
						</div>
						<div className='col'>
							<h4 className='contact-title'>FAQ</h4>
							<ul className='p-0 contact-faqs'>
								<li className='d-flex my-3'>
									<a href='#'>¿Quiénes somos? </a>
								</li>
								{/*<li className='d-flex my-3'>
									<a href='/'>¿Con quiénes devo acudir si tengo diabetes? </a>
								</li>
								<li className='d-flex my-3'>
									<a href='/'>¿Lorem ipsum dolor sit?</a>
								</li>
								*/}
							</ul>
						</div>
					</div>
				</div>
				
				<div className=' col-md-3 py-5'>
					<h4 className='contact-title'>Redes Sociales</h4>
					<ul className='p-0 contact-redes'>
					{
						redes.map((item,i) =>
							<li key={i}className='d-flex my-3'>
								<div className='mr-2'>
									<img src={item.icon} className='contact-icon' alt={item.nombre}/>
								</div>
								<span><a href={item.url}>{item.nombre}</a></span>
							</li>
						)
					}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Contacto;
