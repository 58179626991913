import React from 'react'
import { Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import axios from '../axios'

const Nav = () => {

	let match = useRouteMatch();
	const history = useHistory();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: '/admin/login' } };

	const _logout = () => {
		axios.delete('/api/logout')
		.then(r=>{
			history.replace(from)
		})
		.catch(r=>alert(r))
	}

	return(
		<nav className='my-nav' style={{background:'white', height:80, borderBottom:'1px solid black'}}>
			<div id='mySidenav' className='sidenav'>
				<a href='#!' className='closebtn' onClick={()=>document.getElementById('mySidenav').style.width = '0'}>&times;</a>
					<Link to={`${match.url}/contactos`} style={{color:'white'}}>Contactos</Link>
					<Link to={`${match.url}/aliados`} style={{color:'white'}}>Lista de Aliados</Link>
					<Link to={`${match.url}/apoyo`} style={{color:'white'}}>Lista de programa apoyo</Link>
					<a href='#!' onClick={_logout} style={{color:'white'}}>Salir</a>

			</div>
			<span style={{fontSize:30,cursor:'pointer', visibility:'hidden', position:'absolute', right:10, color:'black'}} onClick={()=>document.getElementById('mySidenav').style.width = '250px'}>&#9776;</span>
			<ul style={{width:'100%'}}>
				<li>
					<Link to={`${match.url}/contactos`} style={{color:'black'}}>Contactos</Link>
				</li>
				<li>
					<Link to={`${match.url}/aliados`} style={{color:'black'}}>Lista de Aliados</Link>
				</li>
				<li>
					<Link to={`${match.url}/apoyo`} style={{color:'black'}}>Lista de programa apoyo</Link>
				</li>
				<li style={{float:'right'}}>
					<a href='#!' onClick={_logout} style={{color:'black'}}>Salir</a>
				</li>
			</ul>
		</nav>
	)
}

export default Nav
