import React, { Component } from 'react';
import './caorusel.css';
import Slider from 'react-slick';
import TituloSection from '../TituloSection';

const ImagesCarousel = () => {

	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		speed: 3000,
		autoplaySpeed:3000,
		cssEase: 'linear',
		responsive: [
		{
			breakpoint: 1200,
			settings: {
			slidesToShow: 3,
			slidesToScroll: 3,
			infinite: true,
			dots: false
			}
		},
		{
			breakpoint: 600,
			settings: {
			slidesToShow: 2,
			slidesToScroll: 2,
			initialSlide: 2
			}
		},
		{
			breakpoint: 480,
			settings: {
			slidesToShow: 1,
			slidesToScroll: 1
			}
		}
			],
	};

	const imgcarousel =[
		{
			url : 'https://sites.google.com/view/ameenf/inicio',
			logo: '/resources/icon0.png',
			alt: 'ameenf'
		},
		{
			url : 'https://afamjal.com.mx/',
			logo: '/resources/icon1.png',
			alt: 'afamjal'
		},
		{
			url : 'https://cimejal.com.mx/',
			logo: '/resources/icon2.png',
			alt: 'cimejal'
		},
		{
			url : 'https://canirac.org.mx/',
			logo: '/resources/icon3.png',
			alt: 'canirac'
		},
		{
			url : 'https://canainma.com/',
			logo: '/resources/icon4.png',
			alt: 'canainma'
		},
		{
			url : 'https://www.helenkelleracgdl.org/',
			logo: '/resources/icon5.png',
			alt: 'helenkeller'
		},
		{
			url : 'https://www.amcham.org.mx/',
			logo: '/resources/icon6.png',
			alt: 'amcham'
		},
		{
			url : 'https://www.google.com.mx',
			logo: '/resources/icon7.png',
			alt: 'index occidente'
		},
		{
			url : 'https://www.ccij.org.mx/',
			logo: '/resources/icon8.png',
			alt: 'ccij'
		},
		{
			url : 'https://www.facebook.com/CRAndares/',
			logo: '/resources/icon9.png',
			alt: 'club rotario andares'
		},
		{
			url : 'https://www.facebook.com/redecytgto/',
			logo: '/resources/icon10.png',
			alt: 'redecytgto'
		},
		{
			url : 'https://www.afamo.com.mx/',
			logo: '/resources/icon11.png',
			alt: 'afamo'
		},
		{
			url : 'http://www.canieti.org/Inicio.aspx',
			logo: '/resources/icon12.png',
			alt: 'canieti'
		},
		{
			url : 'https://www.instagram.com/diabetesvivefeliz',
			logo: '/resources/logos/Diabetes-Vive-Feliz.png',
			alt: 'diabetes vive feliz'
		},
		{
			url : 'https://aquadeyun.com.mx/',
			logo: '/resources/logos/Aqua-Deyun.png',
			alt: 'aqua deyun'
		},
		{
			url : 'https://www.instagram.com/verdemiel_/?hl=es',
			logo: '/resources/logos/Verde-Miel.png',
			alt: 'verdemiel'
		},
		{
			url : 'https://www.dalmendraketo.com/',
			logo: "/resources/logos/D'Almendra.png",
			alt: 'd almendra keto'
		},
		{
			url : 'https://cuatrobiomarket.com/',
			logo: "/resources/logos/cu4tro.png",
			alt: 'cu4trobiomarket'
		},
	];

	
	return (
		<div id='afiliados' className='container-fluid'>
			<div className='rowi p-4'>
				<div style={{height:280}}>
					<div className='container-fluid m-0 p-0'>
						<TituloSection titulo='ALIADOS' tituloColor='#06294A' colorLine='#24B9B6' />
						<div className='container p-0'>
							<Slider {...settings}>
							{imgcarousel.map((item,i)=>
								<div key={i} className='container-logo'	style={{border:'solid purple'}}>
									<a className='m-0 p-2' href={item.url} target='_blank'>
										<img alt={item.alt} src={item.logo} className='col-12 mx-auto d-block logos' alt='...'/>
									</a>
								</div>
							)}
							</Slider>
						</div>
					</div>
				</div>
			</div>
		</div>
	);

}
export default ImagesCarousel
