import React, { useEffect, useState } from 'react';
import axios from '../../axios'
import dateConverter from './dateConverter'

const Contacto = () => {


	const [contactos, setContactos] = useState([])

	useEffect(()=>{
		axios.get('/api/contactos')
		.then(r=>setContactos(r.data))
		.catch(r=>alert(r))
	},[])
	
	return(
		<div style={{background:'white', padding:50}}>
			<h3>
				Contactos WebSite
			</h3>
			<table className='highlight responsive-table'>
				<thead>
					<tr>
						<th>Id</th>
						<th>Correo</th>
						<th>Mensaje</th>
						<th>Fecha</th>
					</tr>
				</thead>
				<tbody>
				{contactos.map((c,i)=>
					<tr>
						<td>{c.id}</td>
						<td><a href={'mailto:'+c.correo}>{c.correo}</a></td>
						<td>{c.mensaje}</td>
						<td>{dateConverter(c.created_at)}</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
	)
}
export default Contacto
