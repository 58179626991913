import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom'
import './menu.css';

const Menu = ({textoMenu}) => {

	const styles = { color: textoMenu };
	const [url, setUrl] = useState('inicio')
	let history = useHistory();
	
	return (
		<div>
			<div className='container-fluid m-0 p-0 '>
				<div className='container-xl p-0'>
					<div className='rowi m-0'>
						<nav className='navbar navbar-expand-lg navbar-dark'>
							<a className='navbar-brand logo' href='#inicio' onClick={()=>history.push('/')}>
								<img src='/resources/logo-color.png' className='mx-auto d-block efecto' alt='...'/>
							</a>
							<button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
								<span className='navbar-toggler-icon'></span>
							</button>
							<div className='collapse navbar-collapse' id='navbarSupportedContent'>
								<ul className='navbar-nav mr-autoi' data-toggle='collapse' data-target='#navbarSupportedContent'>
									<li className={window.location.href.includes('inicio')?'nav-item active':'nav-item'}>
										<a href='#inicio' onClick={()=>history.push('/')} className='nav-link menu' style={styles}>
											Inicio
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('nosotros')?'nav-item active':'nav-item'}>
										<a href='#nosotros' onClick={()=>history.push('/')} className='nav-link menu' href='#nosotros' style={styles}>
											Quienes somos
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('afiliados')?'nav-item active':'nav-item'}>
										<a href='#afiliados' onClick={()=>history.push('/')} className='nav-link menu' style={styles}>
											Aliados
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('noticias')?'nav-item active':'nav-item'}>
										<a href='#noticias' onClick={()=>history.push('/')} className='nav-link menu' style={styles}>
											Noticias
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('blog')?'nav-item active':'nav-item'}>
										<a href='#blog' onClick={()=>history.push('/')} className='nav-link menu' style={styles}>
											Campañas
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('registrate')?'nav-item active':'nav-item'}>
										<a href='#registrate' onClick={()=>history.push('/registrate')} className='nav-link menu' style={styles}>
											Regístrate
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('unidosvsdiabetes')?'nav-item active':'nav-item'}>
										<a href='#section-1' onClick={()=>history.push('/unidosvsdiabetes')} className='nav-link menu' style={styles}>
											Unidos vs Diabetes
											<div className='bordo'></div>
										</a>
									</li>
									<li className={window.location.href.includes('rudosvstecnicos')?'nav-item active':'nav-item'}>
										<a href='#rudosvstecnicos' onClick={()=>history.push('/rudosvstecnicos')} className='nav-link menu' style={styles}>
											Vampiro Canadiense
											<div className='bordo'></div>
										</a>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Menu;
