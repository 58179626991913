import React from 'react';
import './titulosection.css';

const TituloSection = (props) => {

	const {titulo,tituloColor,colorLine} = props

	return (
		<div className='content-title'>
			<h1 className='title' style={{color:tituloColor}}>{titulo}</h1> 
			<div className='line' style={{backgroundColor:colorLine }}></div>
		</div>
	);
};

export default TituloSection;
