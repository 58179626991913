import React from 'react';
import fondoBeneficios from '../../assets/unidosvsdiabetes/Fondos/beneficios.jpg'
import a7 from '../../assets/unidosvsdiabetes/img/Logos/07.jpg' //chopo
import a9 from '../../assets/unidosvsdiabetes/img/Logos/09.jpg'
import a10 from '../../assets/unidosvsdiabetes/img/Logos/10.jpg'
import a11 from '../../assets/unidosvsdiabetes/img/Logos/11.jpg'
import TituloSection from '../TituloSection'

const NuevosAliados = () => {

	return (
		<div id='section-3' className='section3' style={{backgroundImage:'url('+fondoBeneficios+')', backgroundPosition:'bottom',marginTop:100}}>
			<div className='m-row'>
			<TituloSection titulo='Nuevos Aliados' tituloColor='#06294A' colorLine='#24B9B6' />
				<div>
					<center>
						<img alt='laboratorios chopo' src={a7} width='300'/>
						<div style={{color:'grey', fontSize:18}}>Laboratorios CHOPO</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Se une a la ANPS otorgando increíbles descuentos del 50% en exámenes del azúcar<br /> y del 5% al 20% en exámenes clínicos generales.</p>
					</center>
				</div>
				<div style={{borderTop:'3px solid grey', marginTop:100, paddingTop:50}}>
					<center>
						<img alt='aqua deyun' src={a9}/>
						<div style={{color:'grey', fontSize:18}}>Aqua Deyun</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Un producto para bajar los niveles de azúcar en la sangre<br /> y bajar la hemoglobina glicosilada.</p>
					</center>
				</div>
				<div style={{borderTop:'3px solid grey', marginTop:100, paddingTop:50}}>
					<center>
						<img alt='verdemiel' src={a10}/>
						<div style={{color:'grey', fontSize:18}}>Verdemiel</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Un restaurante Healthy que nos otorga el 10 % de descuento <br />en los alimentos procesados por ellos.</p>
					</center>
				</div>
				<div style={{borderTop:'3px solid grey', marginTop:100, paddingTop:50}}>
					<center>
						<img alt='D’almendra Keto Bakery' src={a11}/>
						<div style={{color:'grey', fontSize:18}}>D’almendra Keto Bakery</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Una repostería libre de azúcar y sin gluten que se une otorgando el 20 % de descuento<br />en los productos elaborados por la casa y el 10% en otros productos.</p>
					</center>
				</div>
				<div style={{borderTop:'3px solid grey', marginTop:100, paddingTop:50}}>
					<center>
						<img alt='Cu4tro Biomarket' src='/resources/logos/cu4tro.png'/>
						<div style={{color:'grey', fontSize:18}}>Cu4tro Biomarket</div>
						<p style={{textAlign:'center', fontSize:18, fontWeight:'bold'}}>Una tienda que busca nutrir tú cuerpo, espíritu, mente y el planeta con productos saludables y sustentables.<br />Recibe el 10% de descuento en toda la tienda.</p>
					</center>
				</div>
			</div>
		</div>
	)
}

export default NuevosAliados
