import React, { useEffect, useState } from 'react';
import axios from '../../axios'
import dateConverter from './dateConverter'

const Aliados = () => {


	const [aliados, setAliados] = useState([])

	useEffect(()=>{
		axios.get('/api/aliados')
		.then(r=>setAliados(r.data))
		.catch(r=>alert(r))
	},[])

	const _getExcel = () => {

		axios.get('/api/exportaliados',{responseType: 'blob'})
		.then(r=>{
			const url = window.URL.createObjectURL(new Blob([r.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'aliados.xls');
			document.body.appendChild(link);
			link.click();
		})
		.catch(r=>alert(r))

	}

	return(
		<div style={{background:'white', padding:50}}>
			<h3>
				Aliados Registrados
			</h3>
			<button style={{width:100, height:30, background:'#73D216', color:'white', border:0, fontSize:16, float:'right'}} onClick={_getExcel}>Excel</button>
			<table className='highlight responsive-table'>
				<thead>
					<tr>
						<th>Id</th>
						<th>Nombre</th>
						<th>Correo</th>
						<th>Teléfono</th>
						<th>Estado</th>
						<th>Ciudad</th>
						<th>Empresa/Institución</th>
						<th>Actividad que desempeño</th>
						<th>Me gustaría colaborar</th>
						<th>Fecha de registro</th>
					</tr>
				</thead>
				<tbody>
				{aliados.map((c,i)=>
					<tr key={i}>
						<td>{c.id}</td>
						<td>{c.nombre}</td>
						<td><a href={'mailto:'+c.correo}>{c.correo}</a></td>
						<td>{c.telefono}</td>
						<td>{c.estado}</td>
						<td>{c.ciudad}</td>
						<td>{c.empresainstitucion}</td>
						<td>{c.actividadquedesempeño}</td>
						<td>{c.comomegustariacolaborar}</td>
						<td>{dateConverter(c.created_at)}</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
	)
}
export default Aliados
