import React, { useEffect, useState } from 'react';
import axios from '../../axios'
import dateConverter from './dateConverter'

const Contacto = () => {


	const [programa, setPrograma] = useState([])

	useEffect(()=>{
		axios.get('/api/programaapoyo')
		.then(r=>{setPrograma(r.data);console.log(r.data)})
		.catch(r=>alert(r))
	},[])
	
	const _getExcel = () => {

		axios.get('/api/exportprogramaapoyo',{responseType: 'blob'})
		.then(r=>{
			const url = window.URL.createObjectURL(new Blob([r.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'programa_apoyo.xls');
			document.body.appendChild(link);
			link.click();
		})
		.catch(r=>alert(r))

	}

	return(
		<div style={{background:'white', padding:50}}>
			<h3>
				Registrados programa apoyo
			</h3>
			<button style={{width:100, height:30, background:'#73D216', color:'white', border:0, fontSize:16, float:'right'}} onClick={_getExcel}>Excel</button>
			<table className='highlight responsive-table'>
				<thead>
					<tr>
						<th>Id</th>
						<th>Nombres</th>
						<th>Apellido Paterno</th>
						<th>Apellido Materno</th>
						<th>Correo</th>
						<th>Teléfono</th>
						<th>Estado Civil</th>
						<th>Ocupación</th>
						<th>Cantidad de hijos</th>
						<th>Estatura</th>
						<th>Estado</th>
						<th>Ciudad</th>
						<th>Fecha Nacimiento</th>
						<th>Género</th>
						<th>Cuerpo</th>
						<th>Tengo Diabetes</th>
						<th>Tengo Colesterol</th>
						<th>Tengo Cáncer</th>
						<th>Tengo Asma</th>
						<th>Tengo Depresión</th>
						<th>Padres con diabetes</th>
						<th>Padres con colesterol</th>
						<th>Padres con hipertensión</th>
						<th>Padres con cáncer</th>
						<th>Padres con Asma</th>
						<th>Padres con Depresión</th>
						<th>Padres con ninguna enfermedad</th>
						<th>Padres con</th>
						<th>Tuve covid</th>
						<th>Tuve covid durante</th>
						<th>Es facil hacerme enojar</th>
						<th>Es facil hacerme reír</th>
						<th>Es facil hacerme entristecer</th>
						<th>Salud actual</th>
						<th>Seguro Médico</th>
						<th>Checkeo General</th>
						<th>Checkeo Especificado</th>
						<th>Fumo o Bebo</th>
						<th>Frecuencia Fumar o Beber</th>
						<th>Tengo Medico de confianza</th>
						<th>Me gustaría ser donador</th>
						<th>Tengo daño en el cuerpo</th>
						<th>Descripcion del daño</th>
						<th>Soy alergico</th>
						<th>Descripcion de mi alergia</th>
						<th>Me gustaria economizar el medicamento</th>
						<th>Invertiría en mi salud</th>
						<th>Me gustaría mejorar</th>
						<th>Fecha de registro</th>
					</tr>
				</thead>
				<tbody>
				{programa.map((p,i)=>
					<tr key={i}>
						<td>{p.id}</td>
						<td>{p.nombre}</td>
						<td>{p.apellido_paterno}</td>
						<td>{p.apellido_materno}</td>
						<td><a href={'mailto:'+p.correo}>{p.correo}</a></td>
						<td><a href={`https://wa.me/52${p.telefono}`} target='_blank'>{p.telefono}</a></td>
						<td>{p.estado_civil}</td>
						<td>{p.ocupacion}</td>
						<td>{p.cant_hijos}</td>
						<td>{p.estatura}</td>
						<td>{p.estado}</td>
						<td>{p.ciudad}</td>
						<td>{p.fechanacimiento}</td>
						<td>{p.genero}</td>
						<td>{p.tipocuerpo}</td>
						<td>{p.tengodiabetes==1?'Sí':'No'}</td>
						<td>{p.tengocolesterol==1?'Sí':'No'}</td>
						<td>{p.tengocancer==1?'Sí':'No'}</td>
						<td>{p.tengoasma==1?'Sí':'No'}</td>
						<td>{p.tengodepresion==1?'Sí':'No'}</td>
						<td>{p.padrescondiabetes==1?'Sí':'No'}</td>
						<td>{p.padresconcolesterol==1?'Sí':'No'}</td>
						<td>{p.padresconhipertension==1?'Sí':'No'}</td>
						<td>{p.padresconcancer==1?'Sí':'No'}</td>
						<td>{p.padresconasma==1?'Sí':'No'}</td>
						<td>{p.padrescondepresion==1?'Sí':'No'}</td>
						<td>{p.padresconninguna==1?'Sí':'No'}</td>
						<td>{p.padrescon}</td>
						<td>{p.tuvecovid==1?'Sí':'No'}</td>
						<td>{p.tuvecovid_durante}</td>
						<td>{p.facil_enojar==1?'Sí':'No'}</td>
						<td>{p.facil_reir==1?'Sí':'No'}</td>
						<td>{p.facil_entristecer==1?'Sí':'No'}</td>
						<td>{p.salud_actual}</td>
						<td>{p.seguro_medico}</td>
						<td>{p.checkeo_general}</td>
						<td>{p.checkeo_especificado}</td>
						<td>{p.fumo_bebo==1?'Sí':'No'}</td>
						<td>{p.frecuencia_fumar_beber}</td>
						<td>{p.medico_de_confianza==1?'Sí':'No'}</td>
						<td>{p.megustaria_ser_donador==1?'Sí':'No'}</td>
						<td>{p.daño_en_cuerpo==1?'Sí':'No'}</td>
						<td>{p.daño_en_cuerpo_descripcion}</td>
						<td>{p.alergia==1?'Sí':'No'}</td>
						<td>{p.alergia_descripcion}</td>
						<td>{p.megustaria_economizar_medicamento}</td>
						<td>{p.inversion_para_mi_salud}</td>
						<td>{p.megustariamejorar}</td>
						<td>{dateConverter(p.created_at)}</td>
					</tr>
				)}
				</tbody>
			</table>
		</div>
	)
}
export default Contacto
