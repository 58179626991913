import React from 'react';
import { Link } from 'react-router-dom';
import './cardnoticia.css';
import IconCard from '../../IconCard';
import Slider from 'react-slick';

const CardNoticia = ({id,titulo,description,images,link}) => {

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
	};



	return (
		<>
			<IconCard />
			<div className='card' style={{minHeight:450}}>
				<div className='card-body m-0i p-0'>
					<h5 className='card-title'>{titulo}</h5>
					<p className='card-text m-0 p-0' style={{overflowY:'auto'}}>{description}
					{/*<a href='#' className='btn btn-link m-0 p-0'>Ver más</a>*/}
					</p>
					{link&&
					<a href={link} target='_blank'>{link}</a>
					}
					{/*
					<div className='row m-0 p-0 a-link'>
						<div className='col-md-5 m-0 p-0'></div>
						<div className='col-md-7 m-0 p-0'><a href='#' className='btn btn-link m-0 p-0'>Leer más</a></div>
					</div>
					*/}
				</div>
				<Slider {...settings}>
				{images.map((img,i)=>
					<div key={i}>
					<center>
						<img alt={titulo} src={img.url} height='300' style={{objectFit:'contain',width:'100%'}}/>
						<div style={{position:'absolute', bottom:'0px', zIndex:3, background:'rgba(255,255,255,.2)', width:'100%', height:30}}>{img.titulo}</div>
					</center>
					</div>
				)}
				</Slider>
				<div>
					<Link to={'/noticia/'+id} style={{float:'right',display:'block', background:'#06294A', width:100, padding:10, textAlign:'center',color:'white', borderRadius:5, textDecoration:'none'}}>Ver más</Link>
				</div>
			</div>
		</>
	);
};

export default CardNoticia;
