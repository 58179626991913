import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import noticias from './noticias'


const Noticia = () => {

	const history = useHistory()
	const {noticia_id} = useParams()
	const [noticia, setNoticia] = useState({imagenes:[]})

	useEffect(()=>{
		setNoticia(noticias.find(d=>d.id==noticia_id))
		window.scrollTo(0, 0)
	},[])

	return (
		<div style={{backgroundImage:'url(/resources/bg-header.jpg)',backgroundRepeat:'no-repeat', backgroundPosition:'center',backgroundSize:'cover', backgroundAttachment:'fixed',paddingTop:130,paddingBottom:100, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.05)', minHeight:'100vh'}}>
			<div className='container'>
				<div className="card" style={{}}>
					<div className="card-body">
						<h4>{noticia.titulo}</h4>
						<p>{noticia.descripcion}</p>
						{noticia.imagenes.map((img,i)=>
							img.link!==''?
							<center style={{margin:10}}>
							<a href={img.link} target='_black' style={{margin:10,}}>
								<img alt={noticia.titulo} src={img.url} style={{maxWidth:'100%'}}/>
							</a>
							</center>
							:
							<center style={{margin:10}}>
								<img alt={noticia.titulo} src={img.url} style={{maxWidth:'100%'}}/>
							</center>
						)}
					</div>
					<a href='#noticias' onClick={()=>history.push('/')} style={{float:'right',display:'block', background:'#06294A', width:100, padding:10, textAlign:'center',color:'white', borderRadius:5, textDecoration:'none'}}>Regresar</a>
					
				</div>
			</div>
		</div>
	)
	
}

export default Noticia
