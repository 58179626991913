import React from 'react';
import './iconcard.css';

const IconCard = () => {
	    return (
        <div>
            <div className='text-center d-flex justify-content-center'>
                <div className='cont-bg-icon'>
                    <img src='/resources/icon.png' className='mx-auto d-block icon' alt='...'/>
                </div>
            </div>
        </div>
    );


};

export default IconCard;
