import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import campañas from './campanas'

const Campaña = () => {

	const history = useHistory()
	const {campana_link} = useParams()
	const [campaña, setCampaña] = useState({imagenes:[], videos:[]})

	useEffect(()=>{
		setCampaña(campañas.find(d=>d.link==campana_link))
		window.scrollTo(0, 0)
	},[])

	return (
		<div style={{backgroundImage:'url(/resources/bg-header.jpg)',backgroundRepeat:'no-repeat', backgroundPosition:'center',backgroundSize:'cover', backgroundAttachment:'fixed',paddingTop:130,paddingBottom:100, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.05)', minHeight:'100vh'}}>
			<div className='container'>
				<div className="card" style={{}}>
					<div className="card-body">
						<h4>{campaña.titulo}</h4>
						<p>{campaña.descripcion}</p>
						{campaña.imagenes.map((img,i)=>
							img.link!==''?
							<center key={i} style={{margin:10}}>
							<a href={img.link} target='_black' style={{margin:10,}}>
								<img alt={campaña.titulo} src={img.url} style={{maxWidth:'100%'}}/>
							</a>
							</center>
							:
							<center key={i} style={{margin:10}}>
								<img alt={campaña.titulo} src={img.url} style={{maxWidth:'100%'}}/>
							</center>
						)}
						{
						campaña.videos.map((c,i)=>
							<iframe key={i} width='100%' height='500' src={c.url} title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>
						)}
					</div>
					<a href='#blog' onClick={()=>history.push('/')} style={{float:'right',display:'block', background:'#06294A', width:100, padding:10, textAlign:'center',color:'white', borderRadius:5, textDecoration:'none'}}>Regresar</a>
					
				</div>
			</div>
		</div>
	)
	
}

export default Campaña
