import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import cuadro1 from '../../../../assets/cuadro1.png'
import { estados } from '../../../../geo/estados'
import { ciudades } from '../../../../geo/ciudades'
import axios from '../../../../axios'

const Apoyo = () => {

	const [loader, setLoader] = useState(false)
	const [data, setData] = useState({nombre:'', correo:'', telefono:'', estado:'', ciudad:'', empresainstitucion:'', actividadquedesempeño:'', comomegustariacolaborar:'' })

	useEffect(()=>{
		window.scrollTo(0, 0);
	},[])

	const _push = (e) => {
		e.preventDefault()
		if(!/^[0-9]{10}$/.test(data.telefono)){
			alert('ingrese un teléfono válido')
				return 0
		}
		setLoader(true)
		axios.post('/aliado',data)
		.then(r=>{
			console.log(r.data)
			setData({nombre:'', correo:'', telefono:'', estado:'', ciudad:'', empresainstitucion:'', actividadquedesempeño:'', comomegustariacolaborar:'' })
			setLoader(false)
			alert('gracias por registrarte con consotros!')
		})
		.catch(r=>alert(r))
	}

	return(
		<div id='registrate' style={{backgroundImage:'url(https://www.nasihatler.com/wp-content/uploads/2010/04/dostluk.jpg)',backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundAttachment:'fixed',paddingTop:200,paddingBottom:100, boxShadow: '0 4px 4px rgba(0, 0, 0, 0.05)'}}>
		<center>
		<div style={{display:'block',paddingBottom:100, paddingTop:0}}>
		<form onSubmit={_push}>
			<div style={{margin:0,color:'white', fontSize:30, fontWeight:900}}>
				<div style={{position:'relative',display:'inline-block', padding:20}}>
					<div style={{display:'inline', borderBottom:'4px solid #25969F'}}>Regí</div>strate
				</div>
			</div>
			<div className='card2'>
				<div style={{fontSize:16, fontWeight:'bold', textTransform:'uppercase'}}>Regístrate como aliado</div>
					<p style={{textAlign:'justify'}}>Ayudanos a promover la Salud, todos los esfuerzos suman, si tienes algún producto o servicio que pueda beneficiar a las personas, ¡Regístrate!</p>
					<div style={{textAlign:'left'}}>
					<div>
						<label htmlFor='nombre'>Nombre:</label>
						<input value={data.nombre} onChange={(e)=>setData({...data,nombre:e.target.value})} id='nombre' type='text' required/>
					</div>
					<div>
						<label htmlFor='correo'>Correo electrónico:</label>
						<input value={data.correo} onChange={(e)=>setData({...data,correo:e.target.value})} id='correo' type='email' required/>
					</div>
					<div>
						<label htmlFor='telefono'>Telefono:</label>
						<input value={data.telefono} onChange={(e)=>setData({...data,telefono:e.target.value})} id='telefono' type='text' required/>
						<span style={{color:/^[0-9]{10}$/.test(data.telefono)?'green':'red'}}>{/^[0-9]{10}$/.test(data.telefono)?'teléfono válido':'teléfono inválido'}</span>
					</div>

					<div style={{display:'flex', flexWrap:'wrap',marginTop:10}}>
					<div style={{marginRight:5}}>
						<label htmlFor='' style={{display:'block'}}>Estado:</label>
						<select style={{width:'100%',height:44, fontSize:20}} value={data.estado} onChange={(e)=>setData({...data, estado:e.target.value})}  id='estado' required>
							<option value=''>Seleccione un opción</option>
							{estados.map(e=><option key={e.key} value={e.name}>{e.name}</option>)}
						</select>
					</div>
					<div>
						<label htmlFor='' style={{display:'block'}}>Ciudad:</label>
						<select style={{width:'100%',height:44, fontSize:20}} value={data.ciudad} onChange={(e)=>setData({...data, ciudad:e.target.value})} required>
							<option value=''>Seleccionar Ciudad</option>
								{
								data.estado&&
								ciudades.filter(c=>c.state_id===estados.find(e=>e.name===data.estado).id).map(c=><option key={c.id} value={c.name}>{c.name}</option>)
								}
						</select>
					</div>
					</div>

					<div>
						<label>Empresa/Institución:</label>
						<input value={data.empresainstitucion} onChange={(e)=>setData({...data,empresainstitucion:e.target.value})} type='text' required/>

					</div>



					<div>
						<label>Actividad que desempeña:</label>
						<input value={data.actividadquedesempeño} onChange={(e)=>setData({...data,actividadquedesempeño:e.target.value})} type='text' required/>

					</div>









					<div>
						<label>Como te gustaría colaborar:</label>
						<textarea style={{width:'100%', height:100, fontSize:18}} type='text' value={data.comomegustariacolaborar} onChange={(e)=>setData({...data, comomegustariacolaborar:e.target.value})} required/>
					</div>

					</div>
					{loader?
						<h5>Cargando...</h5>
						:
						<button onClick={()=>console.log(data)} type='submit' style={{backgroundColor:'#2B3663', width:163, height:44, color:'white',border: '1.5px solid #2B3663'}}>Enviar</button>
					}
				</div>
				</form>

			</div>
		</center>
		</div>
	)
}

export default Apoyo
